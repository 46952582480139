import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { FIREBASE_EVENTS, VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { useFirebaseEvent } from 'hooks'
import { setDrawer, setStage } from 'slices/drawer'
import { STATICS } from 'statics'
import { ATTENDENT_HOURS } from './constants'
import './style.scss'

export function RedirectChat() {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  async function openChat() {
    dispatch(setDrawer(''))
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_BEGIN_CHAT)
    window.SigmaChat.showChat()
  }

  function handleClickOpenChat() {
    const hour = new Date().getHours()

    if (hour >= ATTENDENT_HOURS.INITIAL && hour <= ATTENDENT_HOURS.FINAL) {
      openChat()
    } else {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.AFTER_TIME))
    }
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => {
            handleClickOpenChat()
          }}
        >
          Iniciar Chat
        </Button>
      </div>
    )
  }

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <div className='round'>
          <STATICS.TECHNICAL_SUPPORT className='icon-support' />
        </div>
        <h3 className='title'>{'Acesse o chat para falar\ncom nossos atendentes'}</h3>
        <p className='text'>
          Para prosseguir com seu atendimento clique no botão iniciar chat e fale com um de nossos
          especialistas.
        </p>
      </div>
      {renderButton()}
    </div>
  )
}
