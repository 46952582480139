import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { goBack, setStage } from 'slices/drawer'
import { Button } from 'components/button'
import { FIREBASE_EVENTS } from 'app-constants'
import { postConfirmationNegotiation, useFirebaseEvent } from 'hooks'
import { formatCurrency, formatDate } from 'utils'
import { STAGES, NEGOTIATION_CONFIRMATION_ERROR } from '../../constants'
import './style.scss'

export function Confirmation({
  contractId,
  negotiation,
  invoices,
  parcels,
  selectedOption,
  setErrorInfo,
  setIsLoading,
}) {
  const { dueDate } = selectedOption
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_BILLET_NEGOTIATION_CONFIRM)
  }, [sendEvent])

  async function handleClickAccept() {
    try {
      const confirmRequest = {
        invoices,
        parcels,
        contractNumber: contractId,
        idNegotiation: negotiation.id,
        idParcelGroup: selectedOption.idGroup,
        parcelQuantity: selectedOption.quantity,
        negotiation: selectedOption,
      }
      setIsLoading(true)
      await postConfirmationNegotiation(confirmRequest)

      dispatch(setStage(STAGES.SUCCESS))
    } catch (error) {
      console.error(error)
      setErrorInfo(NEGOTIATION_CONFIRMATION_ERROR)
      dispatch(setStage(STAGES.ERROR))
    }
    setIsLoading(false)
  }

  function handleClickBack() {
    dispatch(goBack())
  }

  function renderDueDate() {
    return <span className='due-date'>Vencimento em {formatDate(dueDate, 'dd/MM')}</span>
  }

  function renderPayment() {
    const { quantity, firstParcelValue, parcelValue } = selectedOption

    if (quantity > 1) {
      return (
        <span className='payment'>
          {formatCurrency(firstParcelValue)} + {quantity - 1} x {formatCurrency(parcelValue)}
        </span>
      )
    }

    return (
      <span className='payment'>
        {quantity} x {formatCurrency(firstParcelValue)}
      </span>
    )
  }

  return (
    <div className='container-confirmation'>
      <div className='container-info'>
        <div className='container-title'>
          <span className='title'>Confirmar Negociação</span>
        </div>
        <span className='subtitle'>Você escolheu a seguinte opção:</span>
        <div className='container-option'>
          {renderDueDate()}
          {renderPayment()}
        </div>
        <span className='description first'>Estamos prontos para negociar sua fatura.</span>
        <span className='description'>Você confirma sua escolha?</span>
      </div>
      <div className='container-buttons'>
        <Button onClick={() => handleClickAccept()} color='primary' className='button accept'>
          Confirmar
        </Button>
        <Button onClick={() => handleClickBack()} color='primary' outlined className='button back'>
          Voltar
        </Button>
      </div>
    </div>
  )
}
