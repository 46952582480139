import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FIREBASE_EVENTS, NEW_VIRTUAL_SUPPORT_STAGES, PLAN_STATUS_KEY } from 'app-constants'
import { Button, Card, RadioButton } from 'components'
import { useFirebaseEvent } from 'hooks'
import { setDrawer, setStage } from 'slices/drawer'
import './style.scss'

export function SelectAddress({ contractAddressSelected, setContractAddressSelected }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()
  const {
    customer: { contracts },
  } = useSelector(state => state)

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleContinue() {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_ADDRESS_REPAIR)
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS_CONFIRMATION))
  }

  function handleSelectContractAddress(contract) {
    setContractAddressSelected(contract)
  }

  function renderCards() {
    const contractsFiltred = contracts.filter(
      contract => contract.contractStatus !== PLAN_STATUS_KEY.ST_CONT_CANCELADO
    )

    return contractsFiltred.map(item => (
      <Card
        className={`card ${contractAddressSelected === item ? '-selected' : ''}`}
        onClick={() => handleSelectContractAddress(item)}
        key={item.id}
      >
        <RadioButton id={item.id} checked={contractAddressSelected === item} />
        <div className='card-content'>
          <div className='tag'>
            <p>CONTRATO {item.id}</p>
          </div>
          <p className='text'>
            {`${item.address.street}, ${item.address.number}, ${item.address.neighborhood} - ${item.address.city}, ${item.address.uf}`}
          </p>
          <p className='text'>CEP: {item.address.zipCode}</p>
        </div>
      </Card>
    ))
  }

  function renderButtons() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => handleContinue()}
          disabled={!contractAddressSelected}
        >
          Continuar
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => {
            handleClose()
          }}
        >
          Cancelar
        </Button>
      </div>
    )
  }

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <h3 className='title'>Você está no suporte virtual</h3>
        <p className='subtitle'>Para iniciar, selecione o endereço que necessita de reparo</p>
        {renderCards()}
      </div>
      {renderButtons()}
    </div>
  )
}
