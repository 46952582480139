import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawer, setStage } from 'slices/drawer'
import { updatePaidCall } from 'slices/contract'
import { CALLS_STAGES, EVENTS, FIREBASE_EVENTS } from 'app-constants'
import { useFirebaseEvent } from 'hooks'
import { formatPhone } from 'utils'
import { STATICS } from 'statics'
import { Card } from 'components/card'
import { Toggle } from 'components/toggle'
import { CardButton } from '../card-button'
import './style.scss'

export function PhoneCard({ className }) {
  const {
    selected: { id: idContract },
    telephony,
  } = useSelector(state => state.contract)
  const phoneNumber = useSelector(state => state.contract.telephony?.phoneNumber)
  const { sendEvent } = useFirebaseEvent()
  const { collectCall = false, id: phoneId } = telephony || {}

  const dispatch = useDispatch()

  function handleToggle() {
    sendEvent(FIREBASE_EVENTS.TELEPHONY_COLLECT_CALL)
    dispatch(updatePaidCall({ idContract, phoneId, collectCall: !collectCall }))
  }

  function handleOpenCallsHistory() {
    dispatch(setDrawer('calls'))
    dispatch(setStage(CALLS_STAGES.HISTORY))
  }

  function handleOpenCallsExtract() {
    dispatch(setDrawer('calls', CALLS_STAGES.EXTRACT))
    dispatch(setStage(CALLS_STAGES.EXTRACT))
  }

  if (!phoneId) return null

  return (
    <Card className={`phone-card-component ${className || ''}`}>
      <h3 className='title'>
        Telefone {formatPhone(phoneNumber)}{' '}
        <button type='button' className='button' onClick={() => dispatch(setDrawer('calls'))}>
          Ver todas
        </button>
      </h3>
      <div className='wrapper'>
        <p className='text'>Receber ligações a cobrar</p>
        <Toggle
          className='toggle'
          label={collectCall ? 'Habilitado' : 'Desabilitado'}
          checked={collectCall}
          onChange={handleToggle}
        />
      </div>
      <div className='wrapper -actions'>
        <CardButton icon={<STATICS.CALL_HISTORY />} onClick={handleOpenCallsHistory}>
          Histórico de ligações
        </CardButton>
        <CardButton icon={<STATICS.CALL_EXTRACT />} onClick={handleOpenCallsExtract}>
          Extrato de ligações
        </CardButton>
      </div>
    </Card>
  )
}
