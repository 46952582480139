import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setStage } from 'slices/drawer'
import { NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function UnstableSignal() {
  const dispatch = useDispatch()

  function handleStartClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MODEM_RESET))
  }

  function handleEquipmentInfoClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MODEM_EQUIPMENT_LIST))
  }

  return (
    <div className='unstable-signal'>
      <div className='info-container'>
        <h3 className='title'>Sinal instável</h3>
        <p className='description'>
          Sua rede está enfrentando alguns problemas de conexão. Para isso, vamos realizar alguns
          procedimentos no modem da sua internet para reiniciar a rede
        </p>
      </div>
      <div className='image-container'>
        <img alt='Imagem de verificação de sinal' src={STATICS.ART_UNSTABLE_SIGNAL} />
      </div>
      <div className='actions-container'>
        <Button className='button-component -large' color='primary' onClick={handleStartClick}>
          Vamos lá!
        </Button>
        <Button
          className='button-component -large'
          color='primary'
          outlined
          onClick={handleEquipmentInfoClick}
        >
          Acessar a lista de equipamentos
        </Button>
      </div>
    </div>
  )
}
