import React from 'react'
import { DRAWER_KEY } from 'app-constants'
import { ActivationPendingDrawer } from './activation-pending'
import { AddressChangeDrawer } from './address-change'
import { CallsDrawer } from './calls'
import { CompleteSignupDrawer } from './complete-signup'
import { ConfirmServiceScheduleDrawer } from './confirm-service-schedule'
import { DueDateDrawer } from './due-date'
import { EmailDrawer } from './email'
import { FaqDrawer } from './faq'
import { MessagesDrawer } from './messages'
import { ModemDrawer } from './modem'
import { MyServicesDrawer } from './my-services'
import { NegotiationDrawer } from './negotiation'
import { PasswordDrawer } from './password'
import { PhoneDrawer } from './phone'
import { PlanDrawer } from './plan'
import { ServiceOrdersDrawer } from './service-orders'
import { UnlockAccountDrawer } from './unlock-account'
// import { VirtualSupportDrawer } from './virtual-support'
import { NewVirtualSupport } from './new-virtual-support'

export function DrawerTemplate() {
  return {
    [DRAWER_KEY.ACTIVATION_PENDING]: <ActivationPendingDrawer />,
    [DRAWER_KEY.ADDRESS_CHANGE]: <AddressChangeDrawer />,
    [DRAWER_KEY.CALLS]: <CallsDrawer />,
    [DRAWER_KEY.COMPLETE]: <CompleteSignupDrawer />,
    [DRAWER_KEY.CONFIRM_SERVICE_SCHEDULE]: <ConfirmServiceScheduleDrawer />,
    [DRAWER_KEY.DUE_DATE]: <DueDateDrawer />,
    [DRAWER_KEY.EMAIL]: <EmailDrawer />,
    [DRAWER_KEY.FAQ]: <FaqDrawer />,
    [DRAWER_KEY.MESSAGES]: <MessagesDrawer />,
    [DRAWER_KEY.MODEM]: <ModemDrawer />,
    [DRAWER_KEY.NEGOTIATION]: <NegotiationDrawer />,
    [DRAWER_KEY.PASSWORD]: <PasswordDrawer />,
    [DRAWER_KEY.PHONE]: <PhoneDrawer />,
    [DRAWER_KEY.PLAN]: <PlanDrawer />,
    [DRAWER_KEY.SERVICES]: <MyServicesDrawer />,
    [DRAWER_KEY.SERVICE_ORDERS]: <ServiceOrdersDrawer />,
    [DRAWER_KEY.UNLOCK_ACCOUNT]: <UnlockAccountDrawer />,
    [DRAWER_KEY.VIRTUAL_SUPPORT]: <NewVirtualSupport />,
  }
}
