import React from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS, NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { Button } from 'components'
import './style.scss'

export function SelectAddressConfirmation({ contractAddressSelected }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  function handleContinueClick() {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_CONFIRM_ADDRESS)
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SCHEDULING_SERVICE_ORDER))
  }

  function getLowerCaseAddressInfo() {
    const { city, neighborhood, number, street, uf, zipCode } = contractAddressSelected.address

    return {
      city: city.toLowerCase(),
      neighborhood: neighborhood.toLowerCase(),
      number,
      street: street.toLowerCase(),
      uf,
      zipCode: zipCode.toLowerCase(),
    }
  }

  function renderAddressCard() {
    const { city, neighborhood, number, street, uf, zipCode } = getLowerCaseAddressInfo()

    return (
      <div className='container-card'>
        <span className='title'>Endereço:</span>
        <span className='info'>
          {street}, {number}, {neighborhood} - {city}, {uf}
          <br />
          CEP: {zipCode}
        </span>
      </div>
    )
  }

  function renderActionButtons() {
    return (
      <>
        <Button
          className='button confirm'
          color='primary'
          size='large'
          onClick={handleContinueClick}
        >
          Confirmar
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => handleContinueClick(NEW_VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS)}
        >
          Cancelar
        </Button>
      </>
    )
  }

  return (
    <div className='container-address-confirmation'>
      <div className='container-info'>
        <div className='container-title'>
          <span className='title'>Confirmar Endereço</span>
        </div>
        <span className='contract-info'>
          O endereço que você selecionou é referente ao contrato
          <span className='contract'> {contractAddressSelected.id}</span>
        </span>
        {renderAddressCard()}
        <span className='instruction'>
          Clique abaixo em confirmar se for esse o endereço desejado, ou volte se quiser selecionar
          o reparo para outro endereço
        </span>
      </div>
      <div className='container-buttons'>{renderActionButtons()}</div>
    </div>
  )
}
