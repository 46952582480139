import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchMessages } from 'slices/customer'
import { setStage } from 'slices/drawer'
import { Card } from 'components'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { formatDate } from 'utils'
import { STATICS } from 'statics'
import './style.scss'

export function Messages({ setData }) {
  const { messages } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  function handleMessage(item) {
    sendEvent(FIREBASE_EVENTS.NOTIFICATION_OPEN)
    setData(item)
    dispatch(setStage('message'))
  }

  function handleSeeMore() {
    dispatch(fetchMessages())
  }

  if (!messages) return null

  return (
    <div className='messages-component'>
      <h3 className='title'>Central de Notificações</h3>
      {messages.content.length ? (
        messages.content.map(item => (
          <Card
            className={`card ${item.visualized ? 'read' : 'unread'}`}
            key={item.id}
            onClick={() => handleMessage(item)}
          >
            <div className='item'>
              <p className='title'>{item.title}</p>
              {item.visualized ? <STATICS.ROUNDED_CHECK /> : <p className='oval' />}
            </div>
            <p className='date text'>{formatDate(item.creationDateTime)}</p>
          </Card>
        ))
      ) : (
        <div className='empty'>
          <STATICS.INVOICES_HISTORIC className='icon' />
          <p className='subtitle'>Ainda não existem mensagens.</p>
        </div>
      )}
      {messages.hasMore && (
        <button type='button' className='button' onClick={handleSeeMore}>
          Ver mais
        </button>
      )}
    </div>
  )
}
