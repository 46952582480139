import { useDispatch } from 'react-redux'
import { setDrawer, setStage } from 'slices/drawer'
import { Button } from 'components'
import { STATICS } from 'statics'
import { DRAWER_KEY, NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function StableSignal() {
  const dispatch = useDispatch()
  function handleBackButton() {
    dispatch(setDrawer(DRAWER_KEY.NONE))
  }

  function handleChatClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
  }

  return (
    <div className='stable-signal'>
      <div className='info-container'>
        <h3 className='title'>Sinal estável</h3>
        <p className='description'>Não identificamos nenhuma anormalidade em sua rede.</p>
        <p className='description'>
          Ainda continua com problema? Se desejar, podemos continuar seu atendimento pelo nosso
          chat. Clique abaixo
        </p>
      </div>
      <div className='image-container'>
        <img alt='Imagem de verificação de sinal' src={STATICS.ART_STABLE_SIGNAL} />
      </div>
      <div className='actions-container'>
        <Button className='button-component -large' color='primary' onClick={handleBackButton}>
          Voltar
        </Button>
        <Button
          className='button-component -large'
          color='primary'
          outlined
          onClick={handleChatClick}
        >
          Falar com atendente
        </Button>
      </div>
    </div>
  )
}
