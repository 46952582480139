import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteCustomerContact } from 'slices/customer'
import { clearHistory, setDrawerAlert, setStage } from 'slices/drawer'
import { useFirebaseEvent } from 'hooks'
import { formatPhone } from 'utils'
import {
  DEFAULT_ERROR_MESSAGE,
  EVENT_STATUS,
  FIREBASE_EVENTS,
  REGISTRATION_DATA_STAGES,
} from 'app-constants'
import { Button, Card } from 'components'
import './style.scss'

export function PhoneDelete({ data }) {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  async function handleSubmit() {
    setIsLoading(true)
    sendEvent(FIREBASE_EVENTS.CLICK_ON_CONFIRM_DELETE_PHONE)

    try {
      await dispatch(deleteCustomerContact(data)).unwrap()
      sendEvent(FIREBASE_EVENTS.DELETE_PHONE_SUCCESS, EVENT_STATUS.SUCCESS)

      dispatch(setDrawerAlert({ severity: 'success', title: `Telefone apagado com sucesso!` }))
    } catch (error) {
      sendEvent(FIREBASE_EVENTS.DELETE_PHONE_SUCCESS, EVENT_STATUS.ERROR, error?.response?.status)
      dispatch(setDrawerAlert({ severity: 'error', title: DEFAULT_ERROR_MESSAGE }))
    }

    dispatch(setStage(REGISTRATION_DATA_STAGES.LIST))
    dispatch(clearHistory())
  }

  function renderPhone() {
    return formatPhone(data.contact)
  }

  return (
    <div className='phone-delete-component'>
      <div className='content'>
        <h3 className='title'>Apagar telefone</h3>
        <p className='text'>Deseja apagar o telefone abaixo?</p>
        <Card className='card'>
          <p className='text'>{renderPhone()}</p>
        </Card>
      </div>
      <Button
        type='button'
        color='primary'
        isLoading={isLoading}
        size='large'
        onClick={handleSubmit}
      >
        Sim, apagar telefone
      </Button>
    </div>
  )
}
