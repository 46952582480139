import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { addDoc, collection } from 'firebase/firestore'
import { formatISO } from 'date-fns'
import { EVENT_STATUS, SESSION_KEY } from 'app-constants'
import { useFirebase } from 'hooks/use-firebase'

export function useFirebaseEvent() {
  const { database } = useFirebase()
  const { contract } = useSelector(state => state)

  const sendEvent = useCallback(
    async (event, status = EVENT_STATUS.SUCCESS, statusCode = null) => {
      if (database) {
        try {
          const { fluxo, jornada, label, ordem, title } = event
          const sessionId = localStorage.getItem(SESSION_KEY)
          const contractId = contract?.selected?.id || null
          const data = {
            createdAt: formatISO(Date.now()),
            label,
            title,
            jornada,
            fluxo,
            ordem,
            status,
            contract_number: contractId,
            status_code: statusCode,
            session_id: sessionId,
          }

          const docRef = await addDoc(collection(database, 'events'), data)

          return docRef.id
        } catch (error) {
          console.error(error)
        }
      }
    },
    [database, contract]
  )

  return { sendEvent }
}
