import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { STATICS } from 'statics'
import {
  DEFAULT_ERROR_MESSAGE,
  EVENT_STATUS,
  FIREBASE_EVENTS,
  NEW_VIRTUAL_SUPPORT_STAGES,
  SCHEDULING_SHIFT_LABEL,
} from 'app-constants'
import { Button } from 'components'
import { sendBookingsRepair, useFirebaseEvent } from 'hooks'
import { formatDate } from 'utils'
import './style.scss'

export function ScheduleConfirmation({ bookingSelected, contractAddressSelected }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { sendEvent } = useFirebaseEvent()
  const {
    date,
    turn,
    reference,
    scheduleId,
    timeFrames: { initialTimeFrame, finalTimeFrame },
    bookingCode,
    bucket,
    firstAvailableDate,
    workZoneCode,
  } = bookingSelected
  const { unity, id: contractId } = contractAddressSelected

  async function handleRequestVisit() {
    try {
      setIsLoading(true)
      const params = {
        contractId,
        date,
        reference,
        scheduleId,
        shift: turn,
        unity,
        code: bookingCode,
        initialTimeFrame,
        finalTimeFrame,
        bucket,
        firstAvailableDate,
        workZoneCode,
      }

      await sendBookingsRepair(params)
      sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_SCHEDULE_CONFIRMATION)
      dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SCHEDULING_SUCCESS))
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      console.error(error)
      sendEvent(
        FIREBASE_EVENTS.VIRTUAL_SUPPORT_SCHEDULE_CONFIRMATION,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    } finally {
      setIsLoading(false)
    }
  }

  function handleTalkAttendant() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleRequestVisit()}
        >
          Solicitar visita
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          isLoading={isLoading}
          disabled={isLoading}
          outlined
          onClick={() => handleTalkAttendant()}
        >
          Falar com atendente
        </Button>
      </div>
    )
  }

  return (
    <div className='schedule-confirmation'>
      <div className='content'>
        <h3 className='title'>Resumo da sua solicitação</h3>
        <div className='info-confirmation'>
          <p className='text'>
            Você solicitou uma visita técnica para reparo da sua internet, confira a data agendada:
          </p>
          <div className='container-schedule'>
            <div>
              <h4 className='info-text'>Data de agendamento:</h4>
              <p className='info-text'>{formatDate(date)}</p>
            </div>
            <div>
              <h4 className='info-text'>Turno:</h4>
              <p className='info-text'>
                {SCHEDULING_SHIFT_LABEL[turn]} ({initialTimeFrame} - {finalTimeFrame})
              </p>
            </div>
          </div>
          {reference && (
            <div className='reference'>
              <h4 className='text'>Referência:</h4>
              <p className='text'>{reference}</p>
            </div>
          )}
          <div className='alert'>
            <div className='icon-alert'>
              <STATICS.INFO_OUTLINE />
            </div>
            <p className='alert-schedule'>
              Caso deseje alterar a data agendada entre em contato com o atendimento.
            </p>
          </div>
        </div>
      </div>
      {renderButton()}
    </div>
  )
}
