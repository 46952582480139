import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components'
import { getConnectionStatusRequest } from 'hooks'
import { NEW_VIRTUAL_SUPPORT_STAGES, CONNECTION_STATUS_NAME } from 'app-constants'
import { setStage } from 'slices/drawer'
import { STATICS } from 'statics'
import './style.scss'

export function SignalVerify({ setConnectionStatus }) {
  const [loading, setLoading] = useState(false)
  const { id: contractId } = useSelector(state => state.contract.selected)
  const dispatch = useDispatch()

  async function handleVerifyClick() {
    setLoading(true)
    try {
      const {
        data: { status },
      } = await getConnectionStatusRequest({ contractId })
      setConnectionStatus(status)

      if (status !== CONNECTION_STATUS_NAME.ONLINE) {
        dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.UNSTABLE_SIGNAL))
      } else {
        dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.STABLE_SIGNAL))
      }
    } catch (error) {
      console.error(error)
      dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.UNSTABLE_SIGNAL))
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='signal-verify'>
      <div className='info-container'>
        <h3 className='title'>Verificação de Sinal</h3>
        <p className='description'>Para iniciar, vamos verificar o sinal da sua rede?</p>
      </div>
      <img className='image' alt='Imagem de verificação de sinal' src={STATICS.ART_SIGNAL_VERIFY} />
      <Button
        className='button-component -large'
        isLoading={loading}
        color='primary'
        onClick={handleVerifyClick}
      >
        Iniciar verificação
      </Button>
    </div>
  )
}
