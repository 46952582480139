import React from 'react'
import QRCode from 'react-qr-code'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSelector, useDispatch } from 'react-redux'
import { setStage } from 'slices/modal'
import { STATICS } from 'statics'
import { Button } from 'components'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { PIX_MODAL_STAGES } from '../../constants'
import './style.scss'

export function QrCode() {
  const { data } = useSelector(({ modal }) => modal)
  const dispatch = useDispatch()
  const { qrCode } = data
  const { sendEvent } = useFirebaseEvent()

  function handleCopyToClipboardClick() {
    dispatch(setStage(PIX_MODAL_STAGES.COPY))
    sendEvent(FIREBASE_EVENTS.CLICK_PIX_COPY_CODE)
  }

  function renderButtons() {
    return (
      <div className='container-buttons'>
        <CopyToClipboard text={qrCode}>
          <Button
            onClick={() => handleCopyToClipboardClick()}
            color='primary'
            icon={<STATICS.COPY />}
            className='button copy'
          >
            COPIAR CÓDIGO
          </Button>
        </CopyToClipboard>
      </div>
    )
  }

  function renderQrCode() {
    return (
      <>
        <div className='container-qr-code'>
          <div className='holder-code'>
            <QRCode value={qrCode} />
          </div>
        </div>
        <span className='description'>{qrCode}</span>
      </>
    )
  }

  return (
    <div className='holder'>
      <div className='modal-container'>
        <span className='title'>Código QR da solicitação de pagamento</span>
        {renderQrCode()}
        {renderButtons()}
      </div>
    </div>
  )
}
