import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import {
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  SCHEDULE_TYPE,
  SCHEDULING_SHIFT_LABEL,
  SERVICE_ORDER_TYPES,
  VIRTUAL_SUPPORT_STAGES,
} from 'app-constants'
import {
  Button,
  Input,
  CustomDatePicker,
  ContextLoader,
  RadioButton,
  Card,
  SelectInput,
} from 'components'
import { getBookingsRepair, useFirebaseEvent } from 'hooks'
import { convertISOStringToDate, formatDate, formatZipCode, toISO } from 'utils'
import './style.scss'

export function SchedulingServiceOrder({ contractAddressSelected, setBookingSelected }) {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState(null)
  const [bookingAvailableDays, setBookingAvailableDays] = useState([])
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState(null)
  const [scheduleId, setScheduleId] = useState(null)
  const [firstAvailableDate, setFirstAvailableDate] = useState(null)
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())
  const [reference, setReference] = useState('')
  const [selectedTimeFrames, setSelectedTimeFrames] = useState({})
  const [bookingCode, setBookingCode] = useState('')
  const [bucket, setBucket] = useState('')
  const [workzone, setWorkzone] = useState('')
  const { sendEvent } = useFirebaseEvent()
  const { unity, id: contractId, address } = contractAddressSelected
  const { city, neighborhood, number, street, uf, zipCode } = address
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      try {
        const params = {
          unity,
          contractId,
          number,
          city,
          district: neighborhood,
          street,
          state: uf,
          zipCode: formatZipCode(zipCode),
          serviceOrderType: SERVICE_ORDER_TYPES.REPAIR,
        }

        const { data } = await getBookingsRepair({ params })

        setBookingAvailableDays(data?.bookingDaysAvailable || [])
        setScheduleId(data?.scheduleId)
        setBookingCode(data?.code)
        setFirstAvailableDate(data?.firstAvailableDate)
        setWorkzone(data?.workZoneCode)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        console.error(error)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [month, year, unity, contractId, neighborhood, city, street, number, zipCode, uf, dispatch])

  async function handleContinue() {
    setBookingSelected({
      date: selectedDate,
      turn: selectedShift,
      reference,
      scheduleId,
      timeFrames: selectedTimeFrames,
      bookingCode,
      bucket,
      firstAvailableDate,
      workZoneCode: workzone,
    })

    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_SCHEDULE_DATE)
    dispatch(setStage(VIRTUAL_SUPPORT_STAGES.SCHEDULE_CONFIRMATION))
  }

  async function handleCancel() {
    dispatch(setDrawer(''))
  }

  function mapBookingAvailableDays() {
    return bookingAvailableDays.map(({ date }) => convertISOStringToDate(date))
  }

  const getScheduleData = useCallback(
    date => bookingAvailableDays.find(day => day?.date === toISO(date)),
    [bookingAvailableDays]
  )

  const handleChangeSchedule = useCallback(
    date => {
      setSelectedDate(date)

      const selectedDateScheduleData = getScheduleData(date)

      const defaultShift = selectedDateScheduleData?.bookingShiftAvailabilities[0]
      setSelectedShift(defaultShift?.shift)
      setSelectedTimeFrames(defaultShift || {})
      setAvailableShifts(selectedDateScheduleData?.bookingShiftAvailabilities)
      setBucket(selectedDateScheduleData?.bucket)
    },
    [getScheduleData]
  )

  function getDisableButton() {
    return (
      !selectedDate ||
      !selectedShift ||
      !selectedTimeFrames?.initialTimeFrame ||
      !selectedTimeFrames?.finalTimeFrame
    )
  }

  function getScheduleType() {
    return SCHEDULE_TYPE.TURN
  }

  function getSelectedTimeFramePosition(timeFrameList) {
    return timeFrameList.findIndex(
      ({ initialTimeFrame, finalTimeFrame }) =>
        initialTimeFrame === selectedTimeFrames?.initialTimeFrame &&
        finalTimeFrame === selectedTimeFrames?.finalTimeFrame
    )
  }

  function renderDefaultTurn() {
    return availableShifts.map(({ shift, initialTimeFrame, finalTimeFrame }) => (
      <Card
        className={`card ${selectedShift === shift && '-selected'}`}
        onClick={() => {
          setSelectedShift(shift)
          setSelectedTimeFrames({ initialTimeFrame, finalTimeFrame })
        }}
        key={shift}
      >
        <RadioButton id={shift} checked={selectedShift === shift} />
        <p className='text-card'>
          {SCHEDULING_SHIFT_LABEL[shift]} ({initialTimeFrame} - {finalTimeFrame})
        </p>
      </Card>
    ))
  }

  function renderTurns() {
    return availableShifts.map(({ shift }) => (
      <Card
        className={`card ${selectedShift === shift && '-selected'}`}
        onClick={() => {
          setSelectedTimeFrames({})
          setSelectedShift(shift)
        }}
        key={shift}
      >
        <RadioButton id={shift} checked={selectedShift === shift} />
        <p className='text-card'>{SCHEDULING_SHIFT_LABEL[shift]}</p>
      </Card>
    ))
  }

  function renderTimeFrames() {
    const timeFrameList = availableShifts.filter(({ shift }) => shift === selectedShift)
    return (
      selectedShift && (
        <>
          <p className='text time-frame'>Escolha o horário de instalação</p>
          <SelectInput
            onChange={({ target: { value: index } }) => setSelectedTimeFrames(timeFrameList[index])}
            className='time-frame-select'
            value={getSelectedTimeFramePosition(timeFrameList)}
          >
            <option value=''>Horário da Instalação</option>
            {timeFrameList?.map((timeFrames, index) => {
              const { initialTimeFrame, finalTimeFrame } = timeFrames
              const name = `${initialTimeFrame} - ${finalTimeFrame}`

              return (
                <option key={name} value={index}>
                  {name}
                </option>
              )
            })}
          </SelectInput>
        </>
      )
    )
  }

  function renderTimeFramesShift() {
    return (
      <>
        {renderTurns()}
        {renderTimeFrames()}
      </>
    )
  }

  // TODO: Gabriel Hoffmann 10/11/2022 - https://dev.azure.com/Sumicity/Sumicity/_workitems/edit/9300
  function renderScheduleShifts() {
    const scheduleType = getScheduleType()

    const SCHEDULE_SHIFTS = {
      [SCHEDULE_TYPE.TURN]: renderDefaultTurn,
      [SCHEDULE_TYPE.TIME_FRAME]: renderTimeFramesShift,
    }

    return (
      availableShifts.length > 0 && (
        <div className='service-period'>
          <p className='text'>Escolha o melhor período para o atendimento</p>
          {SCHEDULE_SHIFTS[scheduleType]()}
        </div>
      )
    )
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          isLoading={isLoading}
          disabled={getDisableButton()}
          onClick={() => {
            handleContinue()
          }}
        >
          Continuar
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => {
            handleCancel()
          }}
        >
          Cancelar
        </Button>
      </div>
    )
  }

  function handleMonthChange(date) {
    setMonth(date.getMonth() + 1)
  }

  function handleYearChange(date) {
    setYear(date.getFullYear())
  }

  if (isLoading) return <ContextLoader fixed={false} />

  return (
    <div className='os-scheduling'>
      <div className='content'>
        <h3 className='title'>{'Agendamento de visita\n técnica'}</h3>
        <p className='text'>
          Para prosseguirmos com a resolução do seu problema será necessário agendar uma visita
          técnica ao seu endereço
        </p>
        <p className='text'>Selecione o dia do atendimento</p>
        <CustomDatePicker
          name='calender-sheduling'
          availableDates={mapBookingAvailableDays()}
          onChange={handleChangeSchedule}
          startDate={new Date()}
          selectedDate={selectedDate}
          dataCy='select-schedule-day'
          placeholderText={formatDate(new Date())}
          onMonthChange={date => handleMonthChange(date)}
          onYearChange={date => handleYearChange(date)}
          showMonthDropdown
          showYearDropdown
        />
        {renderScheduleShifts()}
        <p className='text'>Informe um ponto de referência do endereço</p>
        <Input
          name='referencepoint'
          required='true'
          label='Ponto de referência*'
          onChange={event => setReference(event.target.value)}
          value={reference}
        />
      </div>
      {renderButton()}
    </div>
  )
}
