import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import { setDrawer } from 'slices/drawer'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import './style.scss'

export function WithoutOptions() {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION_ILEGIBLE)
  }, [sendEvent])

  function handleClick() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='container-without-options'>
      <div className='container-info'>
        <div className='container-icon'>
          <STATICS.NEGOTIATION_INFO />
        </div>
        <span className='description'>
          Você não possui faturas para negociação. Em caso de dúvidas, entre em contato com a
          Central de Atendimento.
        </span>
      </div>
      <div className='container-buttons'>
        <Button onClick={() => handleClick()} className='button accept' color='primary'>
          Ok! Entendi
        </Button>
      </div>
    </div>
  )
}
