import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useForm, sendEmailConfirmation, useFirebaseEvent } from 'hooks'
import { clearHistory, setDrawerAlert, setStage } from 'slices/drawer'
import { confirmCustomerEmail } from 'slices/customer'
import {
  REGISTRATION_DATA_STAGES,
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { Card } from 'components/card'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { CountdownTime } from 'components/countdown-time'
import { NINETY_SECONDS } from './constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
})

export function EmailConfirmation({ data }) {
  const [canSendCode, setCanSendCode] = useState(false)
  const [sendCodeMessage, setSendCodeMessage] = useState({
    error: false,
    message: '',
  })
  const { sendEvent } = useFirebaseEvent()
  const dispatch = useDispatch()

  const form = useForm({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    async function sendCode() {
      try {
        await sendEmailConfirmation(data.contact)
      } catch (err) {
        const errorMessage = err?.message || DEFAULT_ERROR_MESSAGE

        console.log(err)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    sendCode()
  }, [data.contact, dispatch])

  async function handleSendCode() {
    try {
      await sendEmailConfirmation(data.contact)

      if (canSendCode)
        setSendCodeMessage({
          error: false,
          message: 'Enviamos um novo código para você.',
        })

      setCanSendCode(false)
    } catch (err) {
      const errorMessage = err?.message || DEFAULT_ERROR_MESSAGE

      console.log(err)
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }
  }

  async function handleSubmit(values) {
    try {
      sendEvent(FIREBASE_EVENTS.CLICK_ON_CONFIRM_TOKEN_EMAIL)

      await dispatch(
        confirmCustomerEmail({
          contactId: data.contactId,
          code: values.code,
          email: data.contact,
        })
      ).unwrap()

      sendEvent(FIREBASE_EVENTS.ADD_EMAIL_SUCCESS, EVENT_STATUS.SUCCESS)
      dispatch(setStage(REGISTRATION_DATA_STAGES.SUCCESS))
      dispatch(clearHistory())
    } catch (error) {
      const { message } = error
      sendEvent(FIREBASE_EVENTS.ADD_EMAIL_ERROR, EVENT_STATUS.ERROR, error?.response?.status)

      dispatch(setDrawerAlert({ severity: 'error', title: message }))
    }
  }

  return (
    <div className='email-confirmation-component'>
      <h3 className='title'>Enviamos um código para o seu e-mail</h3>
      <h5 className='subtitle'>
        Enviamos um código para o seu atual e-mail. Confirme-o abaixo para completar a alteração.
      </h5>
      <Card className='card'>
        <p className='title'>E-mail</p>
        <p className='text'>{data.contact}</p>
      </Card>
      <form className='form' onSubmit={form.handleSubmit}>
        <div className='content'>
          <label className='label'>Inserir código recebido</label>
          <Input
            className='input'
            name='code'
            placeholder='Código recebido por e-mail'
            onChange={form.handleChange}
            value={form.values.code}
            hasError={form.errors.code}
          />
          <div className='timer'>
            <Button
              type='button'
              className='button'
              onClick={handleSendCode}
              disabled={!canSendCode}
              color='primary'
            >
              NÃO RECEBI O CÓDIGO
            </Button>
            {!canSendCode && (
              <p className='text'>
                Restam&nbsp;
                <CountdownTime
                  time={NINETY_SECONDS}
                  canSendCode={canSendCode}
                  onFinishCounter={() => {
                    setSendCodeMessage({ error: false, message: '' })
                    setCanSendCode(true)
                  }}
                />
                &nbsp;segundos
              </p>
            )}
          </div>
          {!!sendCodeMessage.message && <p className='message'>{sendCodeMessage.message}</p>}
        </div>
        <Button type='submit' color='primary' size='large' isLoading={form.isSubmitting}>
          Enviar Código
        </Button>
      </form>
    </div>
  )
}
