import React, { useEffect, useState } from 'react'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { FAQ_CATEGORIES_LABELS } from '../../constants'
import { Accordion } from '../accordion'
import './style.scss'

export function FaqCategory({ faq, category, isFeedbackEnabled }) {
  const [categoryFaq, setCategoryFaq] = useState([])
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    const filteredFaq = faq.filter(({ category: currentCategory }) => currentCategory === category)
    setCategoryFaq(filteredFaq)
  }, [category, faq])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.FAQ_QUESTIONS_LIST_VIEW)
  }, [sendEvent])

  function renderQuestions() {
    return categoryFaq.map(({ question, answer, id }) => (
      <Accordion
        isFeedbackEnabled={isFeedbackEnabled}
        key={id}
        title={question}
        description={answer}
        id={id}
      />
    ))
  }

  return (
    <div className='faq-categories-component'>
      <h3 className='title'>Perguntas Frequentes</h3>
      <strong className='subtitle'>{FAQ_CATEGORIES_LABELS[category]}</strong>
      {renderQuestions()}
    </div>
  )
}
