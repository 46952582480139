import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectContract } from 'slices/contract'
import { handleContractChange } from 'slices/customer'
import { FIREBASE_EVENTS } from 'app-constants'
import { useFirebaseEvent } from 'hooks'
import { STATICS } from 'statics'
import './style.scss'

export function ContractSelect({ className }) {
  const {
    customer: { contracts },
    contract: { selected: selectedContract },
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  const handleChange = e => {
    const { value } = e.target
    const selected = contracts.find(contract => contract.id === Number(value))

    sendEvent(FIREBASE_EVENTS.CLICK_ON_MENU_SELECT_CONTRACTS)
    dispatch(selectContract(selected))
    dispatch(handleContractChange())
  }

  return (
    <div
      className={`select-contract-component ${className || ''}`}
      data-testid='contract-select-component'
    >
      <span className='label'>Contrato: {selectedContract.id}</span>
      <select className='combo' onChange={handleChange}>
        {contracts?.map(({ id }) => (
          <option key={id} className='option' value={id}>
            {id}
          </option>
        ))}
      </select>
      <div className='icon'>
        <STATICS.ARROW_DOWN />
      </div>
    </div>
  )
}
