import React from 'react'
import { useDispatch } from 'react-redux'
import { GenericCard } from 'components'
import { setDrawer } from 'slices/drawer'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'

export function PasswordCard({ className, title, buttonLabel, Icon, description }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  function handleDrawer() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_CHANGE_SAC_PASSWORD)
    dispatch(setDrawer('password'))
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleDrawer}
      dataCy='password-card'
    >
      {description}
    </GenericCard>
  )
}
