const COLOR_OPTION = {
  SUCCESS: '#4FD600',
  ERROR: '#FF6969',
  NOT_FOUND: '#ACACAC',
  WARNING: '#FFAB5E',
}

const LABEL_OPTION = {
  CONNECTION_AVAILABLE: 'Conectado',
  NO_CONNECTION: 'Sem conexão',
  SUSPENDED: 'Suspenso por débito',
  ENERGY_FAILURE: 'Falha de energia elétrica',
  DEVICE_NOT_IDENTIFIED: 'Equipamento não identificado',
  ENERGY_BLACKOUT: 'Queda de energia',
}

export const SUSPENDED_CONTRACT_STATUS = {
  COLOR: COLOR_OPTION.ERROR,
  LABEL: LABEL_OPTION.SUSPENDED,
}

export const CONNECTION_STATUS_NAME = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  SUSPENDED: 'SUSPENDED',
  ENERGY_FAILURE: 'ENERGY_FAILURE',
  NO_ENERGY: 'NO_ENERGY',
  ENERGY_BLACKOUT: 'ENERGY_BLACKOUT',
  ENERGY_LOSS: 'ENERGY_LOSS',
  UNKNOWN_EQUIPMENT: 'UNKNOWN_EQUIPMENT',
  COMMUNICATION_FAILURE: 'COMMUNICATION_FAILURE',
  BAD_SIGNAL: 'BAD_SIGNAL',
  SIGNAL_DYING: 'SIGNAL_DYING',
  UNKNOWN: 'UNKNOWN',
}

export const CONNECTION_STATUS = {
  [CONNECTION_STATUS_NAME.ONLINE]: {
    COLOR: COLOR_OPTION.SUCCESS,
    LABEL: LABEL_OPTION.CONNECTION_AVAILABLE,
  },
  [CONNECTION_STATUS_NAME.OFFLINE]: {
    COLOR: COLOR_OPTION.ERROR,
    LABEL: LABEL_OPTION.NO_CONNECTION,
  },
  [CONNECTION_STATUS_NAME.SUSPENDED]: {
    COLOR: COLOR_OPTION.ERROR,
    LABEL: LABEL_OPTION.SUSPENDED,
  },
  [CONNECTION_STATUS_NAME.ENERGY_FAILURE]: {
    COLOR: COLOR_OPTION.WARNING,
    LABEL: LABEL_OPTION.ENERGY_FAILURE,
  },
  [CONNECTION_STATUS_NAME.NO_ENERGY]: {
    COLOR: COLOR_OPTION.WARNING,
    LABEL: LABEL_OPTION.ENERGY_FAILURE,
  },
  [CONNECTION_STATUS_NAME.ENERGY_BLACKOUT]: {
    COLOR: COLOR_OPTION.WARNING,
    LABEL: LABEL_OPTION.ENERGY_BLACKOUT,
  },
  [CONNECTION_STATUS_NAME.ENERGY_LOSS]: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  [CONNECTION_STATUS_NAME.UNKNOWN_EQUIPMENT]: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  [CONNECTION_STATUS_NAME.COMMUNICATION_FAILURE]: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  [CONNECTION_STATUS_NAME.BAD_SIGNAL]: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  [CONNECTION_STATUS_NAME.SIGNAL_DYING]: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  [CONNECTION_STATUS_NAME.UNKNOWN]: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
}
