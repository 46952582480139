import React from 'react'
import { GenericCard } from 'components'
import { FIREBASE_EVENTS, SCREEN_TABLET, URLS } from 'app-constants'
import { useFirebaseEvent } from 'hooks'

export function SpeedTestCard({ className, title, buttonLabel, Icon, description }) {
  const { sendEvent } = useFirebaseEvent()

  function handleClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_INTERNET_SPEED_TEST)
    if (window.innerWidth < SCREEN_TABLET) {
      window.location.href = URLS.SPEED_TEST
    }
    return window.open(URLS.SPEED_TEST, '_blank').focus()
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleClick}
    >
      {description}
    </GenericCard>
  )
}
