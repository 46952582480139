import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  diffInDays,
  formatCurrency,
  formatDate,
  isBefore,
  sendWhatsappMessage,
  subtractDays,
} from 'utils'
import {
  MY_BILLS_PAGE_TAB_LABEL,
  BANKSLIPS,
  EVENTS,
  PIX_PAYMENT_ERROR_MESSAGE,
  CREDIT_CARD_ERROR_MESSAGE,
  COMPONENTS_RENDER,
  MAX_DAYS_OVERDUE_BILL,
  FIREBASE_EVENTS,
  BILLS_TAGS,
} from 'app-constants'
import { useToaster, getPixPaymentInfo, generatePaymentLink, useFirebaseEvent } from 'hooks'
import { setModal } from 'slices/modal'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import { Button } from '../button'
import { EmptyBills } from '../empty-bills'
import { Tag } from '../tag'
import { Card } from '../card'
import './style.scss'

// TODO silva.william 30/05/2022: Remover a lógica do botão de atendente para a segunda via quando for estabilizado a migração dos clientes para Giga+
export function BillsCard({ className }) {
  const [barCodeTooltipText, setBarCodeTooltipText] = useState('Copiar código de barras.')
  const [renderClerkAlert, setRenderClerkAlert] = useState(false)
  const [isLoadingPix, setIsLoadingPix] = useState(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState(false)
  const dispatch = useDispatch()
  const { invoices, bankSlips } = useSelector(state => state.customer.financial)
  const { name } = useSelector(state => state.customer.info)
  const { id: idContract, unity } = useSelector(state => state.contract.selected)
  const dataByContractId = bankSlips.content.filter(data => data.contractId === idContract)
  const { showErrorToast } = useToaster()
  const { sendEvent } = useFirebaseEvent()
  const { hasPendingNegotiated, daysToBreachOfAgreement } = bankSlips

  useEffect(() => {
    if (hasPendingNegotiated) {
      dispatch(
        setModal({ key: 'days_to_breach_agreement', data: { days: daysToBreachOfAgreement } })
      )
    }
  }, [hasPendingNegotiated, daysToBreachOfAgreement, dispatch])

  async function handlePixButtonClick(contractBankSlip) {
    sendEvent(FIREBASE_EVENTS.CLICK_PIX_PAYMENT_BILLET)

    try {
      setIsLoadingPix(true)

      const { qrCode } = contractBankSlip

      if (qrCode) await handlePixPayment(contractBankSlip)
      else await handlePixRedirectLink(contractBankSlip)
    } finally {
      setIsLoadingPix(false)
    }
  }

  async function handlePixRedirectLink(contractBankSlip) {
    try {
      const { id } = contractBankSlip

      const {
        data: { paymentLink },
      } = await generatePaymentLink({ invoiceId: id })

      window.open(paymentLink)
    } catch (error) {
      console.error(error)
      showErrorToast(PIX_PAYMENT_ERROR_MESSAGE)
    }
  }

  async function handlePixPayment(contractBankSlip) {
    try {
      const { value, contractId, id } = contractBankSlip
      const params = {
        value,
        contractId,
        invoiceId: id,
        name,
      }
      const { data } = await getPixPaymentInfo(params)

      if (data?.qrcodetext)
        dispatch(setModal({ key: 'pix_payment', data: { qrCode: data?.qrcodetext } }))
    } catch (error) {
      console.error(error)
      showErrorToast(PIX_PAYMENT_ERROR_MESSAGE)
    }
  }

  function handleOpenUnderstandYourBills() {
    dispatch(setModal({ key: 'understand_your_bills' }))
  }

  function resolveLabel() {
    return dataByContractId.length > 1
      ? `Faturas abertas deste contrato (${dataByContractId.length})`
      : 'Fatura aberta deste contrato'
  }

  function handleNegotiationClick() {
    dispatch(setDrawer('negotiation'))
  }

  function handleBarCodeClick() {
    setBarCodeTooltipText('Código copiado!')
    sendEvent(FIREBASE_EVENTS.CLICK_COPY_BILLET_CODE)
  }

  async function handleCreditCard(contractBankSlip) {
    const { id: invoiceId } = contractBankSlip

    try {
      setIsLoadingCreditCard(true)
      sendEvent(FIREBASE_EVENTS.CLICK_CREDIT_CARD_BILLET_PAYMENT)

      const {
        data: { paymentLink },
      } = await generatePaymentLink({ invoiceId })
      window.open(paymentLink)
    } catch (error) {
      console.error(error)
      showErrorToast(CREDIT_CARD_ERROR_MESSAGE)
    } finally {
      setIsLoadingCreditCard(false)
    }
  }

  function handleBarCodeMouseLeave() {
    setBarCodeTooltipText('Copiar código de barras.')
  }

  function getShouldRenderClerkBillsAlert() {
    return renderClerkAlert && COMPONENTS_RENDER.BANK_SLIPS_CLERK_BUTTON
  }

  function getRenderWithoutBankSlips(dueDate) {
    return COMPONENTS_RENDER.BANK_SLIPS_CLERK_BUTTON ? renderClerkButton() : renderTooltip(dueDate)
  }

  function handleTalkToAttendant() {
    sendWhatsappMessage({ message: 'Olá :)', unity })
  }

  function renderClerkButton() {
    if (!renderClerkAlert) setRenderClerkAlert(true)
    return (
      <Button className='clerk' color='primary' onClick={() => handleTalkToAttendant()}>
        Falar com Atendente
      </Button>
    )
  }

  function renderTooltip(dueDate) {
    // TODO: renan.fernandes - 13/07/2020 - definir como fazer para remover regra de negócio do componente (strategies/services)
    const isExpired = diffInDays(new Date(), dueDate) > BANKSLIPS.MAX_DAYS

    const tooltipText = isExpired
      ? 'É necessário gerar um novo boleto. Por favor, entre em contato conosco.'
      : 'Seu boleto estará disponível em breve.'

    return (
      <>
        <div className='icon' data-for='question-tip' data-tip={tooltipText}>
          <STATICS.DENSE_QUESTION />
        </div>
        <ReactTooltip id='question-tip' />
      </>
    )
  }

  function clickBankBillet(urlFile) {
    sendEvent(FIREBASE_EVENTS.CLICK_DOWNLOAD_BILLET)
    window.open(urlFile)
  }

  function renderNegotiationButton() {
    return (
      <div className='actions'>
        <Button
          className='button'
          icon={<STATICS.BAR_CODE />}
          color='primary'
          onClick={() => handleNegotiationClick()}
        >
          Negociar Fatura
        </Button>
      </div>
    )
  }

  function renderPaymentOptions(contractBankSlip) {
    const { urlFile, digitableLine, dueDate, tags } = contractBankSlip
    const priorDate = subtractDays(new Date(), MAX_DAYS_OVERDUE_BILL)
    const isBreachOfAggrement = tags.includes(BILLS_TAGS.BREACH_OF_AGREEMENT)

    if (isBefore(dueDate, priorDate) && isBreachOfAggrement) {
      return renderNegotiationButton()
    }

    const shouldRenderCreditPayment = COMPONENTS_RENDER.CREDIT_CARD_PAYMENT_BUTTON

    return (
      <div className='actions'>
        <Button
          className='button download'
          icon={<STATICS.ALTERNATIVE_DOWNLOAD />}
          onClick={() => clickBankBillet(urlFile)}
          color='primary'
        >
          Baixar Fatura
        </Button>
        <Button
          isLoading={isLoadingPix}
          disabled={isLoadingPix}
          className='button pix'
          icon={!isLoadingPix && <STATICS.PIX_ICON />}
          color='primary'
          onClick={() => handlePixButtonClick(contractBankSlip)}
        >
          Pagar com PIX
        </Button>
        <CopyToClipboard text={digitableLine}>
          <Button
            className='button copy'
            icon={<STATICS.BAR_CODE />}
            color='primary'
            onMouseEnter={() => handleBarCodeMouseLeave()}
            onClick={() => handleBarCodeClick()}
            data-for='barcode-tip'
            data-tip=''
          >
            Copiar Código
            <ReactTooltip id='barcode-tip' getContent={() => barCodeTooltipText} />
          </Button>
        </CopyToClipboard>
        {shouldRenderCreditPayment && (
          <Button
            className='button credit-card'
            isLoading={isLoadingCreditCard}
            disabled={isLoadingCreditCard}
            icon={!isLoadingCreditCard && <STATICS.CREDIT_CARD />}
            color='primary'
            onClick={() => handleCreditCard(contractBankSlip)}
          >
            Pagar com Cartão
          </Button>
        )}
      </div>
    )
  }

  function renderBankSlip() {
    let component

    if (bankSlips.error) {
      component = (
        <div className='empty'>
          <EmptyBills billType='Falha ao consultar faturas' />
        </div>
      )
    } else if (bankSlips.isLoading) {
      return (
        <div className={className}>
          <div className='bills-card-loading' />
        </div>
      )
    } else if (bankSlips.content) {
      const negotiatedSlip = bankSlips.content.find(
        ({ contractId, tags = [] }) =>
          contractId === idContract && tags.includes(BILLS_TAGS.BREACH_OF_AGREEMENT)
      )
      const firstBankSlip = bankSlips.content.find(
        ({ contractId, tags = [] }) =>
          contractId === idContract && !tags.includes(BILLS_TAGS.IN_NEGOTIATION)
      )
      const contractBankSlip = negotiatedSlip || firstBankSlip

      if (!contractBankSlip) {
        component = (
          <div className='empty'>
            <EmptyBills billType='Não existem faturas abertas para este contrato' />
          </div>
        )
      } else {
        const { value, urlFile, dueDate } = contractBankSlip

        const shouldRenderButton = !!urlFile

        component = (
          <div className='current'>
            <div className='wrapper'>
              <p className='label'>VALOR:</p>
              <p className='value'>{formatCurrency(value)}</p>
            </div>
            <div className='wrapper'>
              <p className='label'>VENCIMENTO:</p>
              <p className='date'>{formatDate(dueDate)}</p>
            </div>
            <div className='wrapper -status'>
              <p className='label'>STATUS:</p>
              <Tag className='tag' color='warning' text='em aberto' />
            </div>
            <div className='wrapper -action'>
              {shouldRenderButton
                ? renderPaymentOptions(contractBankSlip)
                : getRenderWithoutBankSlips(dueDate)}
            </div>
          </div>
        )
      }
    }

    return component
  }

  function clickInvoice(urlFile) {
    window.open(urlFile)
  }

  function renderInvoiceDownload(urlFile) {
    const shouldRender = !!urlFile

    return (
      shouldRender && (
        <div className='icon -download' onClick={() => clickInvoice(urlFile)}>
          <STATICS.DOWNLOAD data-for='download-tip' data-tip='Baixar nota fiscal.' />
          <ReactTooltip id='download-tip' />
        </div>
      )
    )
  }

  function renderInvoices() {
    const { error, content, isLoading } = invoices
    const hasErrorOrEmpty = error || content?.length === 0

    if (isLoading) {
      return (
        <div className={className}>
          <div className='bills-card-loading' />
        </div>
      )
    }

    if (hasErrorOrEmpty) {
      return (
        <div className='empty'>
          <EmptyBills billType='Não existem notas fiscais para esse contrato' />
        </div>
      )
    }

    return content.slice(0, 2).map(({ date, id, urlFile, value }) => (
      <div className='invoice' key={id}>
        <div className='wrapper'>
          <p className='value'>{formatCurrency(value)}</p>
          <p className='date'>{formatDate(date)}</p>
        </div>
        <Tag className='tag' text='emitida' color='success' />
        <div className='actions'>{renderInvoiceDownload(urlFile)}</div>
      </div>
    ))
  }

  return (
    <Card className={`bills-card-component ${className || ''}`}>
      <h3 className='title'>Minhas faturas</h3>
      <div className='right-upper-menu' onClick={() => handleOpenUnderstandYourBills()}>
        <div className='icon -lightBlue' data-tip='Entenda suas faturas'>
          <STATICS.DENSE_QUESTION />
        </div>
        <ReactTooltip />
      </div>
      <div className='wrapper'>
        <h4 className='subtitle'>{resolveLabel()}</h4>
        <Link
          className='more'
          to={`/minhas-faturas#${MY_BILLS_PAGE_TAB_LABEL.BANKSLIPS}`}
          onClick={() => {
            sendEvent(FIREBASE_EVENTS.CLICK_SEE_BILLETS)
          }}
        >
          Ver todas (
          {`${bankSlips.content.length} ${bankSlips.content.length === 1 ? ' fatura' : ' faturas'}`}
          )
        </Link>
      </div>
      {renderBankSlip()}
      {getShouldRenderClerkBillsAlert() && (
        <span className='clerk-warning'>
          Clique em <strong>Falar com Atendente</strong> para solicitar a sua segunda via!
        </span>
      )}
      <div className={`wrapper ${renderClerkAlert ? 'margin' : ''}`}>
        <h4 className='subtitle'>Notas fiscais</h4>
        <Link className='more' to={`/minhas-faturas#${MY_BILLS_PAGE_TAB_LABEL.INVOICES}`}>
          Ver todos
        </Link>
      </div>
      {renderInvoices()}
    </Card>
  )
}
