import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'components'
import { useSelector } from 'react-redux'
import { FIREBASE_EVENTS } from 'app-constants'
import { getTvActivationLink, useFirebaseEvent } from 'hooks'
import './style.scss'

export function TvPlanCard({ disabled, tvPlanName, handleClick, buttonLabel }) {
  const [isLoading, setIsLoading] = useState(false)
  const [link, setLink] = useState('')
  const {
    selected: { id: idContract },
    detail,
  } = useSelector(({ contract }) => contract)
  const tvPlan = useMemo(
    () => detail?.contractAdditionals?.find(item => item.itemCode === tvPlanName),
    [detail?.contractAdditionals, tvPlanName]
  )
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    async function getTvLink() {
      setIsLoading(true)

      try {
        const { productId } = tvPlan
        const params = { contractId: idContract, productId }
        const { data } = await getTvActivationLink(params)

        setLink(data.activationLink)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (tvPlan) getTvLink()
  }, [tvPlan, idContract, tvPlanName])

  async function handleButtonClick() {
    if (handleClick) {
      handleClick(tvPlan, idContract)
      return
    }

    sendEvent(FIREBASE_EVENTS.CLICK_ON_SVA_GO_TO_WEBSITE)
  }

  function renderTvPlanName() {
    return <h3 className='title'>{tvPlan?.itemName}</h3>
  }

  function renderAction() {
    if (!link) return null

    return (
      <div className='action'>
        <a className='link' href={link} rel='noreferrer' target='_blank'>
          <Button
            className='button'
            isLoading={isLoading}
            disabled={disabled}
            onClick={handleButtonClick}
          >
            {buttonLabel || 'IR PARA O SITE'}
          </Button>
        </a>
      </div>
    )
  }

  function renderContent() {
    if (!tvPlan) return null

    const { urlLogo, urlImage, description } = tvPlan

    return (
      <Card className='tv-plan-component genericlarge'>
        <div className='header'>
          <img className='image' src={urlLogo || urlImage} alt={`Logo ${tvPlanName}`} />
          {renderTvPlanName()}
        </div>
        <div className='content'>
          {renderTvPlanName()}
          <p className='text'>{description}</p>
        </div>
        {renderAction()}
      </Card>
    )
  }

  return renderContent()
}
