import { useCallback } from 'react'
import { addDoc, collection } from 'firebase/firestore'
import { formatISO } from 'date-fns'
import { BRAND_NAME, SESSION_KEY, DEFAULT_DOCUMENT, DEFAULT_ORIGIN } from 'app-constants'
import { useFirebase } from 'hooks/use-firebase'

export function useFirebaseLogin() {
  const { database } = useFirebase()

  const sendLoginEvent = useCallback(
    async document => {
      if (database) {
        try {
          const data = {
            createdAt: formatISO(Date.now()),
            source: DEFAULT_ORIGIN,
            document: document || DEFAULT_DOCUMENT,
            brand: BRAND_NAME,
          }

          const docRef = await addDoc(collection(database, 'sessions'), data)
          localStorage.setItem(SESSION_KEY, docRef.id)
        } catch (error) {
          console.error(error)
        }
      }
    },
    [database]
  )

  return { sendLoginEvent }
}
