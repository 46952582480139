import React, { useEffect } from 'react'
import { useFirebaseEvent } from 'hooks'
import { EVENT_STATUS, FIREBASE_EVENTS } from 'app-constants'
import { ModemError } from '../error'

export function ModemErrorServer() {
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.WIFI_INFO_ERROR, EVENT_STATUS.ERROR)
  }, [sendEvent])

  return <ModemError title='Não foi possível carregar seus dados de internet' />
}
