import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { format } from 'date-fns'
import {
  DEFAULT_ERROR_MESSAGE,
  EVENT_STATUS,
  FIREBASE_EVENTS,
  SCHEDULING_SHIFT_LABEL,
  SERVICE_ORDER_TYPES,
  VIRTUAL_SUPPORT_STAGES,
} from 'app-constants'
import { updateServiceOrder, useFirebaseEvent } from 'hooks'
import { Button } from 'components'
import { formatDate } from 'utils'
import './style.scss'

export function RescheduleConfirmation({ bookingSelected }) {
  const [isLoading, setIsLoading] = useState(false)
  const {
    date,
    reference,
    turn,
    serviceOrderId,
    timeFrames: { initialTimeFrame, finalTimeFrame },
    bookingCode,
  } = bookingSelected
  const idContract = useSelector(state => state.contract.selected.id)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  async function handleRequestVisit() {
    sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_CONFIRM)
    setIsLoading(true)

    try {
      const params = {
        date: format(date, 'yyyy-MM-dd'),
        shift: turn,
        code: bookingCode,
        reference,
        contractId: idContract,
        serviceOrderType: SERVICE_ORDER_TYPES.REPAIR,
      }
      await updateServiceOrder(serviceOrderId, params)

      sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_SUCCESS, EVENT_STATUS.SUCCESS)
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.SCHEDULING_SUCCESS))
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      console.error(error)
      sendEvent(
        FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_ERROR,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    } finally {
      setIsLoading(false)
    }
  }

  function handleTalkAttendant() {
    dispatch(setStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleRequestVisit()}
        >
          Solicitar visita
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleTalkAttendant()}
        >
          Falar com atendente
        </Button>
      </div>
    )
  }

  return (
    <div className='reschedule-confirmation'>
      <div className='content'>
        <h3 className='title'>Reagendamento da visita de reparo</h3>
        <div className='info-confirmation'>
          <p className='text'>Você solicitou o reagendamento da visita de reparo.</p>
          <p className='text margin'>Confira o resumo da solicitação:</p>
          <div className='container-reschedule'>
            <div className='row'>
              <h4 className='info-title'>Data de agendamento:</h4>
              <p>{formatDate(date)}</p>
            </div>
            <div className='row'>
              <h4 className='info-title'>Turno:</h4>
              <p>
                {SCHEDULING_SHIFT_LABEL[turn]} ({initialTimeFrame} - {finalTimeFrame})
              </p>
            </div>
            {reference && (
              <div className='row'>
                <h4 className='info-title'>Referência:</h4>
                <p>{reference}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {renderButton()}
    </div>
  )
}
