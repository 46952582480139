import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Turnstile from 'react-turnstile'
import { useForm, sendPasswordRecoverySms, useFirebaseEvent } from 'hooks'
import { handlePasswordRecovery } from 'slices/customer'
import { MESSAGES, ORIGIN, FIREBASE_EVENTS, EVENT_STATUS, CLOUDFLARE_SECRET } from 'app-constants'
import { formatCnpj, formatCpf, isValidDocumentLength } from 'utils'
import { Button } from '../button'
import { PasswordAlert } from '../password-alert'
import { PasswordHeader } from '../password-header'
import { PasswordLogo } from '../password-logo'
import { Input } from '../input'
import './style.scss'

const validationSchema = Yup.object().shape({
  document: Yup.string()
    .required(MESSAGES.REQUIRED)
    .test('document', MESSAGES.INVALID_DOCUMENT, (value = '') => isValidDocumentLength(value)),
})

export function PasswordRecovery({ title, subtitle, buttonLabel, nextStep }) {
  const [error, setError] = useState('')
  const [hasVerifyCaptcha, setHasVerifyCaptcha] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  const form = useForm({
    initialValues: {
      document: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_FORGOT_PASSWORD)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEvent])

  useEffect(() => {
    const errors = [...Object.values(form.errors)]

    if (errors[0]) setError({ title: errors[0] })
  }, [form.errors])

  function formatDocument(document) {
    const unmasked = document.replace(/\D/g, '')

    return unmasked.length > 11 ? formatCnpj(unmasked) : formatCpf(unmasked)
  }

  function handleCaptchaVerify() {
    setHasVerifyCaptcha(true)
  }

  async function handleSubmit(values) {
    const { document } = values

    try {
      const {
        data: { phone, email },
        status,
      } = await sendPasswordRecoverySms({ document, brand: ORIGIN })

      sendEvent(FIREBASE_EVENTS.SEND_DOCUMENT_FORGOT_PASSWORD, EVENT_STATUS.SUCCESS, status)

      dispatch(
        handlePasswordRecovery({
          contact: { phone, email },
          document,
        })
      )

      history.push(nextStep)
    } catch (err) {
      setError({
        title: 'Ocorreu um erro ao enviar o seu código.',
        text: 'Verifique o documento inserido ou tente novamente mais tarde.',
      })

      sendEvent(
        FIREBASE_EVENTS.SEND_DOCUMENT_FORGOT_PASSWORD,
        EVENT_STATUS.ERROR,
        err?.response?.status
      )
    }
  }

  function handleAlertClose() {
    setError(null)
  }

  return (
    <div className='password-recovery-page'>
      <PasswordLogo />
      <PasswordAlert error={error} onClose={handleAlertClose} />
      <form className='form' onSubmit={form.handleSubmit}>
        <PasswordHeader title={title} subtitle={subtitle} />
        <Input
          className='input'
          name='document'
          label='CPF ou CNPJ'
          maxLength='18'
          value={formatDocument(form.values.document)}
          onChange={form.handleChange}
          hasError={form.errors.document}
        />
        <div className='container-captcha'>
          <Turnstile
            size='flexible'
            theme='light'
            sitekey={CLOUDFLARE_SECRET}
            onVerify={handleCaptchaVerify}
          />
        </div>
        <Button
          className='button'
          type='submit'
          color='primary'
          size='large'
          disabled={!hasVerifyCaptcha}
          isLoading={form.isSubmitting}
        >
          {buttonLabel}
        </Button>
      </form>
    </div>
  )
}
