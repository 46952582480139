import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setModal } from 'slices/modal'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import { FIREBASE_EVENTS } from 'app-constants'
import { useFirebaseEvent } from 'hooks'
import './style.scss'

export function PasswordResetModal() {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  function handleClick() {
    sendEvent(FIREBASE_EVENTS.ON_CHANGE_PASSWORD_SUCCESS)
    push('/')
    dispatch(setModal({ key: '' }))
  }

  return (
    <div className='password-reset-modal'>
      <div className='round'>
        <div className='icon'>
          <STATICS.ROUNDED_LOCK />
        </div>
      </div>
      <h3 className='title'>Sua senha foi criada com sucesso!</h3>
      <p className='text'>Vá até a página de Login e acesse nossa central com sua nova senha.</p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi
      </Button>
    </div>
  )
}
