import { MODEM_EQUIPMENTS_SUPPORT_URL } from 'app-constants'
import { STATICS } from 'statics'

export const MODEM_EQUIPMENTS = [
  {
    NAME: 'Android TV',
    URL: MODEM_EQUIPMENTS_SUPPORT_URL.ANDROID_TV,
    IMAGE: STATICS.MODEM_ANDROID_TV,
  },
  {
    NAME: 'NOKIA',
    URL: MODEM_EQUIPMENTS_SUPPORT_URL.NOKIA,
    IMAGE: STATICS.MODEM_NOKIA,
  },
  {
    NAME: 'HOMECAST',
    URL: MODEM_EQUIPMENTS_SUPPORT_URL.HOMECAST,
    IMAGE: STATICS.MODEM_HOMECAST,
  },
  {
    NAME: 'FiberHome',
    URL: MODEM_EQUIPMENTS_SUPPORT_URL.FIBERHOME,
    IMAGE: STATICS.MODEM_FIBERHOME,
  },
  {
    NAME: 'HUAWEI',
    URL: MODEM_EQUIPMENTS_SUPPORT_URL.HUAWEI,
    IMAGE: STATICS.MODEM_HUAWEI,
  },
]
