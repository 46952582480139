import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setStage } from 'slices/drawer'
import { NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function ModemReset() {
  const dispatch = useDispatch()

  function handleNextClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MODEM_AWAIT))
  }

  return (
    <div className='modem-reset'>
      <div className='info-container'>
        <h3 className='title'>Reiniciar modem</h3>
        <p className='description'>
          Tudo pronto por aí? Para reiniciar seu modem, vamos fazer um passo a passo. Mas, não se
          preocupe: é simples e rápido!
        </p>
        <p className='step'>
          <span className='step-number'>1</span>
          <span className='step-text'>Desconecte os cabos de alimentação do seu modem.</span>
        </p>
      </div>
      <div className='image-container'>
        <img className='image' src={STATICS.MODEM_SIDEWAY} alt='Modem visto de lado' />
      </div>
      <div className='actions-container'>
        <Button className='button-component -large' color='primary' onClick={handleNextClick}>
          Pronto, desconectei
        </Button>
      </div>
    </div>
  )
}
