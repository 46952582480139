import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { Button } from 'components/button'
import { FIREBASE_EVENTS } from 'app-constants'
import { formatCurrency, formatDate } from 'utils'
import { useFirebaseEvent } from 'hooks'
import { TotalNegotiationCard } from '../total-card'
import { STAGES } from '../../constants'
import './style.scss'

export function InvoicesList({ invoices }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION_LIST)
  }, [sendEvent])

  // TODO natalia.silva 21/12/23 - descomentar quando funcionalidade de status for desenvolvida
  // function renderStatusTag() {
  //   return <Tag className='tag' color='danger' text='Vencida' />
  // }

  function handleButtonClick() {
    dispatch(setStage(STAGES.OPTIONS))
  }

  function renderInvoices() {
    return invoices.map(invoice => (
      <div key={invoice.id} className='invoice-card'>
        <span className='date'>{formatDate(invoice.expirationDate)}</span>
        <span className='value'>{formatCurrency(invoice.value)}</span>
        {/* <span className='status-label'>Status:</span>
        {renderStatusTag()} */}
      </div>
    ))
  }

  return (
    <span className='negotiation-invoice-list'>
      <h3 className='title'>Negociar Pagamento</h3>
      <TotalNegotiationCard invoices={invoices} />
      <div className='invoice-list'>
        <h4 className='title'>Faturas a serem negociadas</h4>
        {renderInvoices()}
      </div>
      <Button className='button' color='primary' onClick={handleButtonClick}>
        Próximo
      </Button>
    </span>
  )
}
