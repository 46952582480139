import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { clearHistory, setDrawerAlert, setStage } from 'slices/drawer'
import { confirmCustomerPhone } from 'slices/customer'
import { sendCellphoneConfirmation, useFirebaseEvent, useForm } from 'hooks'
import { formatPhone, formatPhoneToDB } from 'utils'
import {
  REGISTRATION_DATA_STAGES,
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { Card } from 'components/card'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { CountdownTime } from 'components/countdown-time'
import './style.scss'

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
})

export function PhoneConfirmation({ data }) {
  const [canSendCode, setCanSendCode] = useState(false)
  const [sendCodeMessage, setSendCodeMessage] = useState({
    error: false,
    message: '',
  })
  const { sendEvent } = useFirebaseEvent()
  const dispatch = useDispatch()
  const form = useForm({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    async function sendCode() {
      try {
        await sendCellphoneConfirmation(formatPhoneToDB(data.contact))
      } catch (err) {
        const errorMessage = err?.message || DEFAULT_ERROR_MESSAGE
        console.error(err)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    sendCode()
  }, [data.contact, dispatch])

  async function handleSendCode() {
    try {
      await sendCellphoneConfirmation(formatPhoneToDB(data.contact))

      if (canSendCode)
        setSendCodeMessage({
          error: false,
          message: 'Enviamos um novo código para você.',
        })

      setCanSendCode(false)
    } catch (err) {
      const errorMessage = err?.message || DEFAULT_ERROR_MESSAGE
      console.error(err)
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }
  }

  async function handleSubmit(values) {
    try {
      sendEvent(FIREBASE_EVENTS.CLICK_ON_ADD_PHONE_CONFIRM_TOKEN)

      await dispatch(
        confirmCustomerPhone({
          contactId: data.contactId,
          code: values.code,
          phone: formatPhoneToDB(data.contact),
        })
      ).unwrap()

      sendEvent(FIREBASE_EVENTS.ADD_PHONE_SUCCESS, EVENT_STATUS.SUCCESS)
      dispatch(setStage(REGISTRATION_DATA_STAGES.SUCCESS))
      dispatch(clearHistory())
    } catch (error) {
      const { message } = error

      sendEvent(FIREBASE_EVENTS.ADD_PHONE_ERROR, EVENT_STATUS.ERROR, error?.response?.status)
      dispatch(setDrawerAlert({ severity: 'error', title: message }))
    }
  }

  function renderPhone() {
    return formatPhone(data.contact)
  }

  return (
    <div className='phone-confirmation-component'>
      <h3 className='title'>Enviamos um código para o seu telefone atual</h3>
      <span className='subtitle'>
        Enviamos um código para o seu atual telefone. Confirme-o abaixo para completar a alteração.
      </span>
      <Card className='card'>
        <span className='title'>Telefone</span>
        <span className='text'>{renderPhone()}</span>
      </Card>
      <form className='form' onSubmit={form.handleSubmit}>
        <div className='content'>
          <label className='label'>Inserir código recebido</label>
          <Input
            className='input'
            name='code'
            placeholder='Código recebido por telefone'
            onChange={form.handleChange}
            value={form.values.code}
            hasError={form.errors.code}
          />
          <div className='timer'>
            <Button
              type='button'
              className='button'
              onClick={handleSendCode}
              disabled={!canSendCode}
              color='primary'
            >
              NÃO RECEBI O CÓDIGO
            </Button>
            {!canSendCode && (
              <span className='text'>
                Restam&nbsp;
                <CountdownTime
                  canSendCode={canSendCode}
                  onFinishCounter={() => {
                    setSendCodeMessage({ error: false, message: '' })
                    setCanSendCode(true)
                  }}
                />
                &nbsp;segundos
              </span>
            )}
          </div>
          {!!sendCodeMessage.message && <span className='message'>{sendCodeMessage.message}</span>}
        </div>
        <Button
          className='button'
          type='submit'
          color='primary'
          size='large'
          isLoading={form.isSubmitting}
        >
          Enviar Código
        </Button>
      </form>
    </div>
  )
}
