import React from 'react'
import { useSelector } from 'react-redux'
import { STATICS } from 'statics'
import { sendWhatsappMessage } from 'utils'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { WHATSAPP_MESSAGE } from './constants'
import './style.scss'

export function FileUploadWhatsappModal() {
  const { unity } = useSelector(({ contract }) => contract.selected)
  const { sendEvent } = useFirebaseEvent()

  function handleSendWhatsappClick() {
    sendEvent(FIREBASE_EVENTS.SEND_FILE_TALK_WITH_ATTENDANT)
    sendWhatsappMessage({ message: WHATSAPP_MESSAGE, unity })
  }

  function renderButton() {
    return (
      <button type='button' className='whatsapp-button' onClick={handleSendWhatsappClick}>
        <STATICS.WHATSAPP_BADGE /> Falar com atendente
      </button>
    )
  }

  function renderContent() {
    return (
      <>
        <div className='round -green'>
          <div className='icon'>
            <STATICS.SQUARE_RECEIPT />
          </div>
        </div>
        <h3 className='title'>Envio de arquivo</h3>
        <p className='text'>
          Deseja enviar um arquivo? Entre em contato conosco que iremos te ajudar!
        </p>
        {renderButton()}
      </>
    )
  }

  return <div className='file-upload-whatsapp'>{renderContent()}</div>
}
