import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setStage } from 'slices/drawer'
import { NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function ModemCableReconnect() {
  const dispatch = useDispatch()

  function handleNextClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MODEM_ALMOST_DONE))
  }

  return (
    <div className='modem-cable-reconnect'>
      <div className='info-container'>
        <h3 className='title'>Reconecte os cabos...</h3>
        <p className='step'>
          <span className='step-number'>3</span>
          <span className='step-text'>
            Agora, você já pode conectar todos os cabos de volta no modem.
          </span>
        </p>
      </div>
      <div className='image-container'>
        <img className='image' src={STATICS.MODEM_CABLE} alt='Cabos de energia' />
      </div>
      <div className='actions-container'>
        <Button className='button-component -large' color='primary' onClick={handleNextClick}>
          Os cabos estão conectados
        </Button>
      </div>
    </div>
  )
}
