import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectContract } from 'slices/contract'
import { setStage, setDrawerAlert } from 'slices/drawer'
import { getDueDateDays, updateDueDateContract, useFirebaseEvent } from 'hooks'
import { DEFAULT_ERROR_MESSAGE, FIREBASE_EVENTS, EVENT_STATUS } from 'app-constants'
import { Button } from 'components/button'
import './style.scss'

export function DueDate() {
  const { selected: selectedContract, detail: contractDetails } = useSelector(
    ({ contract }) => contract
  )
  const [dueDateDay, setDueDateDay] = useState()
  const [dueDateDays, setDueDateDays] = useState([])
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    async function fetchDueDateDays() {
      try {
        const { data } = await getDueDateDays()

        setDueDateDays(data)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

        console.error(error)
        sendEvent(
          FIREBASE_EVENTS.CHANGE_DUE_DATE_ERROR,
          EVENT_STATUS.ERROR,
          error?.response?.status
        )
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    fetchDueDateDays()
  }, [dispatch, sendEvent])

  function selectDay(day) {
    setDueDateDay(day)
  }

  async function handleSelectDay() {
    const body = {
      day: dueDateDay.day,
    }

    const newContract = {
      ...selectedContract,
      dueDate: dueDateDay.day,
    }

    try {
      await updateDueDateContract(selectedContract.id, body)
      sendEvent(FIREBASE_EVENTS.CHANGE_DUE_DATE_SELECTION)

      dispatch(setStage('success'))
      dispatch(selectContract(newContract))
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

      console.error(error)
      sendEvent(
        FIREBASE_EVENTS.CHANGE_DUE_DATE_SELECTION,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }
  }

  return (
    <div className='due-date-component'>
      <div className='content'>
        <h3 className='title'>Alterar data de vencimento</h3>
        <p>Dia de Vencimento Atual: {contractDetails?.dueDate}</p>
        <div className='billet-box'>
          <div className='infos'>Selecione o dia de vencimento:</div>
          <div className='radios-wrapper'>
            {dueDateDays &&
              dueDateDays.map(item => (
                <label className='radio-item' key={item.day} htmlFor={`day-${item.day}`}>
                  <input
                    type='radio'
                    name='billetDueDate'
                    value={item.day}
                    id={`day-${item.day}`}
                    className='input'
                    onChange={() => selectDay(item)}
                  />
                  <span className='label-day'>{item.day}</span>
                </label>
              ))}
          </div>
        </div>
        <div className='advise'>
          <h4 className='advise-title'>Atenção:</h4>
          <span>
            A alteração da data de vencimento pode ocasionar a cobrança proporcional dos dias de
            utilização até a nova data escolhida. <br />
            Lembre-se que após sua confirmação você só poderá alterar a data de vencimento novamente
            em 6 meses.
          </span>
        </div>
      </div>
      <Button
        type='button'
        color='primary'
        size='large'
        onClick={handleSelectDay}
        disabled={!dueDateDay}
      >
        Alterar data vencimento
      </Button>
    </div>
  )
}
