import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { DRAWER_KEY } from 'app-constants'
import { Button } from 'components'
import { MODEM_EQUIPMENTS } from '../../constants'
import './style.scss'

export function ModemEquipmentList() {
  const dispatch = useDispatch()

  function handleBackClick() {
    dispatch(setDrawer(DRAWER_KEY.NONE))
  }

  function renderEquipmentList() {
    return MODEM_EQUIPMENTS.map(equipment => (
      <a
        key={equipment.NAME}
        href={equipment.URL}
        className='equipment-content'
        target='_blank'
        rel='noreferrer'
      >
        <img src={equipment.IMAGE} alt={`Exemplo de um modem da marca ${equipment.NAME}`} />
        <div className='equipment-info'>
          <span className='title'>Para clientes com</span>
          <h4 className='name'>{equipment.NAME}</h4>
        </div>
      </a>
    ))
  }

  return (
    <div className='modem-equipment-list'>
      <div className='info-container'>
        <h3 className='title'>Escolha o seu aparelho</h3>
        <p className='description'>
          Identifique o modelo do seu aparelho, e clique na imagem para fazer o download do manual
          explicativo
        </p>
      </div>
      <div className='equipment-container'>{renderEquipmentList()}</div>
      <div className='actions-container'>
        <Button color='primary' className='button-component -large' onClick={handleBackClick}>
          Voltar
        </Button>
      </div>
    </div>
  )
}
