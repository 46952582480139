import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebaseEvent } from 'hooks'
import { fetchContracts, fetchContacts, fetchCustomer } from 'slices/customer'
import { selectContract } from 'slices/contract'
import { FIREBASE_EVENTS } from 'app-constants'
import { ContextLoader } from 'components'
import { STATICS } from 'statics'
import { ContractCard } from './components'
import './style.scss'

export function ContractSelect() {
  const {
    customer: {
      contracts,
      info: { name },
    },
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const userName = useMemo(() => name.split(' ')[0].toLowerCase(), [name])
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_MENU_ACCESS_CONTRACT)
    dispatch(fetchContracts())
    dispatch(fetchCustomer())
    dispatch(fetchContacts())
  }, [dispatch, sendEvent])

  function handleClick(contract) {
    dispatch(selectContract(contract))
  }

  function renderPage() {
    let component

    if (contracts?.length > 0 && !!name) {
      component = (
        <div className='contract-select-page'>
          <div className='container'>
            <img className='logo' src={STATICS.LOGO} alt='sumicity logo' />
            <div className='wrapper'>
              <h1 className='title'>Olá {userName},</h1>
              <h1 className='subtitle'>Selecione qual contrato deseja acessar:</h1>
              {contracts?.map(contract => (
                <ContractCard
                  key={contract.id}
                  className='item'
                  onClick={() => handleClick(contract)}
                  contract={contract}
                />
              ))}
            </div>
          </div>
        </div>
      )
    } else {
      component = <ContextLoader />
    }

    return component
  }

  return renderPage()
}
