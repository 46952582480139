import { useDispatch } from 'react-redux'
import { setDrawer, setStage } from 'slices/drawer'
import { Button } from 'components'
import { STATICS } from 'statics'
import { CONNECTION_STATUS_NAME, DRAWER_KEY, NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function ModemFinish({ connectionStatus }) {
  const dispatch = useDispatch()

  function isConnectionStatusAllowToOpenServiceOrder() {
    return (
      connectionStatus === CONNECTION_STATUS_NAME.BAD_SIGNAL ||
      connectionStatus === CONNECTION_STATUS_NAME.OFFLINE
    )
  }

  function handleBackButton() {
    dispatch(setDrawer(DRAWER_KEY.NONE))
  }

  function handleChatClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
  }

  function handleOpenNewServiceOrderClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS))
  }

  function renderMainActionLabel() {
    if (isConnectionStatusAllowToOpenServiceOrder()) {
      return 'Abrir chamado'
    }

    return 'Falar com atendente'
  }

  function renderMainActionButton() {
    if (isConnectionStatusAllowToOpenServiceOrder()) {
      return (
        <Button
          className='button-component -large'
          color='primary'
          onClick={handleOpenNewServiceOrderClick}
        >
          {renderMainActionLabel()}
        </Button>
      )
    }
    return (
      <Button className='button-component -large' color='primary' onClick={handleChatClick}>
        {renderMainActionLabel()}
      </Button>
    )
  }

  return (
    <div className='modem-finish'>
      <div className='info-container'>
        <h3 className='title'>Sua internet voltou a funcionar corretamente?</h3>
        <p className='description'>
          Deu tudo certo por aí? Se sua internet voltou a funcionar, você pode clicar no botão de
          “encerrar” ali embaixo.
        </p>
        <p className='description'>
          Caso sua internet ainda esteja com instabilidade, clique em “{renderMainActionLabel()}”
          para que possamos continuar te ajudando!
        </p>
      </div>
      <div className='image-container'>
        <img alt='Imagem de conserto de internet' src={STATICS.ART_INTERNET_FIXED} />
      </div>
      <div className='actions-container'>
        {renderMainActionButton()}
        <Button
          className='button-component -large'
          color='primary'
          outlined
          onClick={handleBackButton}
        >
          Encerrar
        </Button>
      </div>
    </div>
  )
}
