import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setStage } from 'slices/drawer'
import { NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function ModemAwait() {
  const dispatch = useDispatch()

  function handleNextClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MODEM_CABLE_RECONNECT))
  }

  return (
    <div className='modem-await'>
      <div className='info-container'>
        <h3 className='title'>Aguarde um pouquinho...</h3>
        <p className='step'>
          <span className='step-number'>2</span>
          <span className='step-text'>
            Agora, aguarde de 2 a 3 minutos para que o modem seja totalmente desligado. Ah,
            importante: TODAS as luzes precisam estar apagadas.
          </span>
        </p>
      </div>
      <div className='image-container'>
        <img className='image' src={STATICS.MODEM_FRONT} alt='Modem visto de frente' />
      </div>
      <div className='actions-container'>
        <Button className='button-component -large' color='primary' onClick={handleNextClick}>
          Todas as luzes apagaram
        </Button>
      </div>
    </div>
  )
}
