import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCondominiums, getCondominiumViability, useFirebaseEvent } from 'hooks'
import { setStage } from 'slices/drawer'
import { Button, RadioButton } from 'components'
import { EVENT_STATUS, FIREBASE_EVENTS } from 'app-constants'
import { INITIAL_COMDOMINIUM_BLOCK_INFO_STATE, ADDRESS_CHANGE_STAGES } from '../../constants'
import './style.scss'

export function AddressChangeCondominium({ data: userData, setData }) {
  const [condominiums, setCondominiums] = useState([])
  const [condominiumAndBlockInfo, setCondominiumAndBlockInfo] = useState(
    INITIAL_COMDOMINIUM_BLOCK_INFO_STATE
  )
  const [isLoading, setIsLoading] = useState({ confirm: false, condominium: false })
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    async function handleCondominiums() {
      try {
        setIsLoading({ ...isLoading, condominium: true })
        const params = getUserAddressInfo()
        const { data } = await getCondominiums(params)
        const isEmptyCondominiums = !data || data.length === 0

        if (isEmptyCondominiums)
          dispatch(setStage(ADDRESS_CHANGE_STAGES.CONDOMINIUM_NOT_REGISTERED))

        setCondominiums(data)
      } catch (error) {
        console.error(error)
        dispatch(setStage(ADDRESS_CHANGE_STAGES.ERROR))
      } finally {
        setIsLoading({ ...isLoading, condominium: false })
      }
    }

    handleCondominiums()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSelectCondominium(condominium) {
    const { name: condominiumLabel, fullCode: condominiumFullCode } = condominium
    const hasBlocks = getComdominiumHasBlocks(condominium)
    const { name: blockLabel, fullCode: blockFullCode } = getSingleBlockInfo(condominium)

    if (!hasBlocks) setIsSubmitDisabled(false)
    else setIsSubmitDisabled(true)

    setCondominiumAndBlockInfo({
      blockLabel,
      blockFullCode,
      condominiumLabel,
      condominiumFullCode,
    })
  }

  function handleSelectBlock(event, condominium) {
    const { value } = event.target
    const block = condominium.blocks.find(({ fullCode }) => fullCode === value)
    const { name: blockLabel, fullCode: blockFullCode } = block

    setIsSubmitDisabled(false)
    setCondominiumAndBlockInfo({ ...condominiumAndBlockInfo, blockLabel, blockFullCode })
  }

  async function handleClickConfirm() {
    try {
      setIsLoading({ ...isLoading, confirm: true })
      const { city, district, number, state, street } = getUserAddressInfo()

      const params = {
        city,
        district,
        street,
        number,
        state,
        condominiumFullCode: condominiumAndBlockInfo.condominiumFullCode,
        blockFullCode: condominiumAndBlockInfo.blockFullCode,
      }

      const {
        data: { preReservationUUID },
      } = await getCondominiumViability(params)

      setData({ ...userData, preReservationUUID, neighborhood: district })
      sendEvent(FIREBASE_EVENTS.INFORM_NEW_ADDRESS)

      dispatch(setStage(ADDRESS_CHANGE_STAGES.BOOKING))
    } catch (error) {
      console.error(error)

      sendEvent(FIREBASE_EVENTS.INFORM_NEW_ADDRESS, EVENT_STATUS.ERROR)
      dispatch(setStage(ADDRESS_CHANGE_STAGES.ADDRESS_INVIABILITY))
    } finally {
      setIsLoading({ ...isLoading, confirm: false })
    }
  }

  function getUserAddressInfo() {
    const { street, number, neighborhood: district, city, state } = userData

    return {
      street,
      number,
      district,
      city,
      state,
    }
  }

  function getComdominiumHasBlocks(condominium) {
    return condominium.blocks.length > 1
  }

  function getSingleBlockInfo(condominium) {
    const { blocks } = condominium
    const hasOneBlock = blocks.length === 1

    if (hasOneBlock) return blocks[0]

    return { name: '', fullCode: null }
  }

  function getIsCondominiumSelected(condominium) {
    const { fullCode } = condominium
    const { condominiumFullCode } = condominiumAndBlockInfo

    return condominiumFullCode === fullCode
  }

  function getIsBlockDisabled(condominium) {
    const hasBlocks = getComdominiumHasBlocks(condominium)
    const isCondominiumSelected = getIsCondominiumSelected(condominium)

    return !hasBlocks || !isCondominiumSelected
  }

  function getInitialOptionForBlocks(blocks) {
    const hasOneOrNoneBlock = blocks.length <= 1
    const optionText = hasOneOrNoneBlock ? 'Bloco Único' : 'Bloco'

    return (
      <option className='placeholder' value=''>
        {optionText}
      </option>
    )
  }

  function renderBlockOptions(blocks) {
    return (
      <>
        {getInitialOptionForBlocks(blocks)}
        {blocks.map(block => (
          <option key={`block-${block.fullCode}`} value={block.fullCode}>
            {block.name}
          </option>
        ))}
      </>
    )
  }

  function renderCondominiumOption() {
    return condominiums.map(condominium => {
      const isBlockSelectedDisabled = getIsBlockDisabled(condominium)
      const idRadioButton = `condominium-${condominium.fullCode}`
      return (
        <div key={condominium.fullCode} className='condominium-option'>
          <label
            onChange={() => handleSelectCondominium(condominium)}
            className='condominium-option-label'
            htmlFor={idRadioButton}
          >
            <RadioButton
              onChange={() => {}}
              id={idRadioButton}
              name='condominium'
              circleClassName='condominium-input'
              checked={getIsCondominiumSelected(condominium)}
            />
            <span className='condominium-name'>{condominium.name}</span>
          </label>
          <fieldset className='block-fieldset'>
            <select
              className='block-select'
              onChange={e => handleSelectBlock(e, condominium)}
              disabled={isBlockSelectedDisabled}
            >
              {renderBlockOptions(condominium.blocks)}
            </select>
            <div className='arrow' />
          </fieldset>
        </div>
      )
    })
  }

  return (
    <div className='address-change-condominium'>
      <div className='condominium-list'>
        <h3 className='title'>Altere seu endereço de instalação</h3>
        <span className='subtitle'>Escolha o seu condominio:</span>
        <div className={`condominium-options ${isLoading.condominium ? 'loading' : ''}`}>
          {renderCondominiumOption()}
        </div>
      </div>
      <Button
        className='button'
        type='submit'
        color='primary'
        size='large'
        onClick={handleClickConfirm}
        disabled={isSubmitDisabled}
        isLoading={isLoading.confirm}
      >
        Continuar
      </Button>
    </div>
  )
}
