import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { STATICS } from 'statics'
import { DRAWER_KEY, FIREBASE_EVENTS, SERVICE_ORDER_TYPES } from 'app-constants'
import { ContextLoader } from 'components'
import { setDrawer } from 'slices/drawer'
import { getServiceOrders, useFirebaseEvent, useToaster } from 'hooks'
import { GET_SERVICE_ORDERS_ERROR_MESSAGE, MOTIVES, STATUS } from '../../constants'
import './style.scss'

export function ServiceOrders() {
  const [serviceOrders, setServiceOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()
  const { showErrorToast } = useToaster()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_INITIAL)
  }, [sendEvent])

  useEffect(() => {
    async function getInfoServiceOrders() {
      try {
        const response = await getServiceOrders(idContract)

        setServiceOrders(response.data)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || GET_SERVICE_ORDERS_ERROR_MESSAGE
        showErrorToast(errorMessage)
      } finally {
        setIsLoading(false)
      }
    }

    getInfoServiceOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContract])

  function handleClick({ isScheduled, serviceOrderType }) {
    if (isScheduled) {
      if (serviceOrderType === SERVICE_ORDER_TYPES.ACTIVATION) {
        return dispatch(setDrawer(DRAWER_KEY.ACTIVATION_PENDING))
      }

      if (serviceOrderType === SERVICE_ORDER_TYPES.REPAIR) {
        return dispatch(setDrawer(DRAWER_KEY.VIRTUAL_SUPPORT))
      }

      if (serviceOrderType === SERVICE_ORDER_TYPES.ADDRESS_CHANGE) {
        return dispatch(setDrawer(DRAWER_KEY.ADDRESS_CHANGE))
      }
    }
  }

  function renderServiceOrders() {
    return serviceOrders.map(serviceOrder => {
      const { scheduleDate, id, motive, status, period, serviceOrderType } = serviceOrder
      const formattedSchedule = format(new Date(scheduleDate), 'dd/MM/yyyy', { locale: ptBR })
      const isScheduled = status === STATUS.SCHEDULE
      const scheduledClassname = isScheduled ? 'scheduled' : ''

      return (
        <button
          className={`container-infos ${scheduledClassname}`}
          onClick={() => handleClick({ isScheduled, serviceOrderType })}
          type='button'
        >
          <div className='container-info'>
            <div className='info'>
              <span className='title'>Protocolo de atendimento</span>
              <span className='subtitle'>{id}</span>
            </div>

            {isScheduled && <STATICS.ARROW_RIGHT className='icon' />}
          </div>

          <div className='container-info'>
            <div className='info'>
              <span className='text'>
                <strong>Data de agendamento: </strong>
                {formattedSchedule}
              </span>

              <span className='text'>
                <strong>Período: </strong>
                {period}
              </span>

              <span className='text'>
                <strong>Motivo: </strong>
                {MOTIVES[motive]}
              </span>
            </div>
            <span className={`status ${scheduledClassname}`}>{status}</span>
          </div>
        </button>
      )
    })
  }

  function renderServiceOrdersTableOrWarning() {
    const hasServiceOrders = serviceOrders.length > 0

    if (!hasServiceOrders)
      return (
        <p className='subtitle message'>
          Não encontramos chamados para o seu contrato nos últimos 90 dias.
        </p>
      )

    return renderServiceOrders()
  }

  if (isLoading) return <ContextLoader fixed={false} />

  return (
    <div className='my-services-component custom-drawer'>
      <h3 className='title'>Ordens de serviço</h3>
      <div className='contet-wrapper'>{renderServiceOrdersTableOrWarning()}</div>
    </div>
  )
}
