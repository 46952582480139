import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { confirmPasswordRecoveryToken, sendPasswordRecoverySms, useFirebaseEvent } from 'hooks'
import {
  ORIGIN,
  RESEND_TOKEN_TIME,
  TOKEN_LENGTH,
  EVENT_STATUS,
  FIREBASE_EVENTS,
} from 'app-constants'
import { handlePasswordRecovery } from 'slices/customer'
import { Button } from '../button'
import { PasswordAlert } from '../password-alert'
import { PasswordHeader } from '../password-header'
import { PasswordLogo } from '../password-logo'
import { CountdownTime } from '../countdown-time'
import { OtpInput } from '../otp-input'
import './style.scss'

export function ValidateToken({ nextStep, prevStep, title }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingResend, setIsLoadingResend] = useState(false)
  const [token, setToken] = useState('')
  const [error, setError] = useState('')
  const [canSendCode, setCanSendCode] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { passwordRecovery } = useSelector(state => state.customer)
  const { sendEvent } = useFirebaseEvent()

  async function handleValidateToken() {
    setIsLoading(true)

    try {
      const { status } = await confirmPasswordRecoveryToken({
        customerDocument: passwordRecovery.document,
        token,
        brand: ORIGIN,
      })

      sendEvent(FIREBASE_EVENTS.CONFIRM_TOKEN_FORGOT_PASSWORD, EVENT_STATUS.SUCCESS, status)

      dispatch(handlePasswordRecovery({ ...passwordRecovery, token }))

      history.push(nextStep)
    } catch (err) {
      setError({
        title: 'Ocorreu um erro ao validar o seu código.',
        text: 'Verifique o código inserido ou tente novamente mais tarde.',
      })

      sendEvent(
        FIREBASE_EVENTS.CONFIRM_TOKEN_ERROR_FORGOT_PASSWORD,
        EVENT_STATUS.ERROR,
        err?.response?.status
      )
    } finally {
      setIsLoading(false)
    }
  }

  if (!passwordRecovery) {
    history.push(prevStep)

    return null
  }

  function getIsButtonDisabled() {
    return token.length < TOKEN_LENGTH
  }

  function handleAlertClose() {
    setError(null)
  }

  async function handleResendCode() {
    setIsLoadingResend(true)

    try {
      const { document } = passwordRecovery
      const {
        data: { phone, email },
        status,
      } = await sendPasswordRecoverySms({ document, brand: ORIGIN })

      sendEvent(FIREBASE_EVENTS.RESEND_TOKEN_FORGOT_PASSWORD, EVENT_STATUS.SUCCESS, status)

      dispatch(
        handlePasswordRecovery({
          contact: { phone, email },
          document,
        })
      )

      setCanSendCode(false)
    } catch (err) {
      setError({
        title: 'Ocorreu um erro ao enviar o seu código.',
        text: 'Tente novamente mais tarde.',
      })

      sendEvent(
        FIREBASE_EVENTS.RESEND_TOKEN_FORGOT_PASSWORD,
        EVENT_STATUS.ERROR,
        err?.response?.status
      )
    } finally {
      setIsLoadingResend(false)
    }
  }

  function renderSubtitle() {
    return `Enviamos um código para o número de telefone ${passwordRecovery.contact.phone} e para o
    e-mail ${passwordRecovery.contact.email} cadastrados. Por favor, informe-o abaixo:`
  }

  return (
    <div className='validate-token-page'>
      <PasswordLogo />
      <PasswordAlert error={error} onClose={handleAlertClose} />
      <div className='text-container'>
        <PasswordHeader title={title} subtitle={renderSubtitle()} />
        <div className='resend-token'>
          Requisite novamente em{' '}
          <CountdownTime
            onFinishCounter={() => setCanSendCode(true)}
            time={RESEND_TOKEN_TIME}
            canSendCode={canSendCode}
          />
        </div>
        <OtpInput maxLength={TOKEN_LENGTH} setCode={setToken} />
        <Button
          className='button'
          type='button'
          color='primary'
          size='large'
          isLoading={isLoadingResend}
          onClick={handleResendCode}
          disabled={!canSendCode}
        >
          Reenviar código
        </Button>
        <Button
          className='button'
          type='submit'
          color='primary'
          size='large'
          isLoading={isLoading}
          onClick={handleValidateToken}
          disabled={getIsButtonDisabled()}
        >
          Próximo
        </Button>
      </div>
    </div>
  )
}
