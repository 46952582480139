import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DEFAULT_ERROR_MESSAGE,
  NEW_VIRTUAL_SUPPORT_STAGES,
  SERVICE_ORDER_TYPES,
  STATUS_CODE,
} from 'app-constants'
import { getServiceOrderDetails } from 'hooks'
import { setDrawerAlert } from 'slices/drawer'
import { ContextLoader } from 'components/context-loader'
import {
  AutoSupportInfo,
  ServiceOrderInfo,
  MassiveWarning,
  ReschedulingServiceOrder,
  CancelConfirmation,
  RescheduleConfirmation,
  SchedulingSuccess,
  CancelSuccess,
  SignalVerify,
  StableSignal,
  UnstableSignal,
  ModemEquipmentList,
  ModemReset,
  ModemAwait,
  ModemCableReconnect,
  ModemAlmostDone,
  ModemFinish,
  SchedulingServiceOrder,
  ScheduleConfirmation,
  SelectAddress,
  SelectAddressConfirmation,
  RedirectChat,
} from './components'

function getStage(props) {
  return {
    [NEW_VIRTUAL_SUPPORT_STAGES.INITIAL]: <AutoSupportInfo {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SERVICE_ORDER]: <ServiceOrderInfo {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MASSIVE_WARNING]: <MassiveWarning {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.RESCHEDULING_SERVICE_ORDER]: (
      <ReschedulingServiceOrder {...props} />
    ),
    [NEW_VIRTUAL_SUPPORT_STAGES.CANCEL_CONFIRMATION]: <CancelConfirmation {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.RESCHEDULE_CONFIRMATION]: <RescheduleConfirmation {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SCHEDULING_SUCCESS]: <SchedulingSuccess {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.CANCEL_SUCCESS]: <CancelSuccess {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SIGNAL_VERIFY]: <SignalVerify {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.STABLE_SIGNAL]: <StableSignal {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.UNSTABLE_SIGNAL]: <UnstableSignal {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MODEM_EQUIPMENT_LIST]: <ModemEquipmentList {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MODEM_RESET]: <ModemReset {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MODEM_AWAIT]: <ModemAwait {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MODEM_CABLE_RECONNECT]: <ModemCableReconnect {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MODEM_ALMOST_DONE]: <ModemAlmostDone {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.MODEM_FINISH]: <ModemFinish {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SCHEDULING_SERVICE_ORDER]: <SchedulingServiceOrder {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SCHEDULE_CONFIRMATION]: <ScheduleConfirmation {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS]: <SelectAddress {...props} />,
    [NEW_VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS_CONFIRMATION]: (
      <SelectAddressConfirmation {...props} />
    ),
    [NEW_VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT]: <RedirectChat {...props} />,
  }
}

export function NewVirtualSupport() {
  const [serviceOrder, setServiceOrder] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [bookingSelected, setBookingSelected] = useState(null)
  const [connectionStatus, setConnectionStatus] = useState(null)
  const [contractAddressSelected, setContractAddressSelected] = useState(null)
  const { stage } = useSelector(({ drawer }) => drawer)
  const {
    selected: { id },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()

  useEffect(() => {
    async function loadServiceOrder() {
      try {
        setIsLoading(true)
        const { data } = await getServiceOrderDetails(id, SERVICE_ORDER_TYPES.REPAIR)

        setServiceOrder(data)
      } catch (error) {
        const errorStatus = error?.response?.status
        const isControlledErrors =
          errorStatus === STATUS_CODE.NOT_FOUND || errorStatus === STATUS_CODE.BAD_REQUEST

        if (isControlledErrors) console.error(error)
        else {
          dispatch(
            setDrawerAlert({
              severity: 'error',
              title: error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
            })
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadServiceOrder()
  }, [dispatch, id])

  if (isLoading) return <ContextLoader fixed={false} />

  return getStage({
    serviceOrder,
    setBookingSelected,
    bookingSelected,
    connectionStatus,
    setConnectionStatus,
    contractAddressSelected,
    setContractAddressSelected,
  })[stage]
}
