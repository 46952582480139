import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setStage } from 'slices/drawer'
import { NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import './style.scss'

export function ModemAlmostDone() {
  const dispatch = useDispatch()

  function handleNextClick() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MODEM_FINISH))
  }

  return (
    <div className='modem-almost-done'>
      <div className='info-container'>
        <h3 className='title'>Estamos quase lá...</h3>
        <p className='step'>
          <span className='step-number'>4</span>
          <span className='step-text'>
            Aguarde até que a luz da Internet fique acesa constantemente, sem piscar.
          </span>
        </p>
      </div>
      <div className='image-container'>
        <img className='image' src={STATICS.MODEM_FRONT} alt='Modem visto de frente' />
      </div>
      <div className='actions-container'>
        <Button className='button-component -large' color='primary' onClick={handleNextClick}>
          As luzes estão acesas
        </Button>
      </div>
    </div>
  )
}
