import { useEffect, useState } from 'react'
import { getVisitTrackingLink, useFirebaseEvent, useToaster } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import './style.scss'

export function TrackingButton({ osId, className }) {
  const [visitTrackingLink, setVisitTrackingLink] = useState('')
  const { showErrorToast } = useToaster()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    async function fetchVisitTrackingLink() {
      if (osId) {
        try {
          const {
            data: { trackingUri },
          } = await getVisitTrackingLink(osId)
          setVisitTrackingLink(trackingUri)
        } catch (error) {
          const errorMessage =
            error?.response?.data?.message || 'Erro ao buscar link para rastreio de visita'
          showErrorToast(errorMessage)
        }
      }
    }

    fetchVisitTrackingLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osId])

  function handleTrackClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SERVICE_ORDER_TRACK)
  }

  return (
    visitTrackingLink && (
      <a
        className={`tracking-button-component ${className}`}
        href={visitTrackingLink}
        onClick={handleTrackClick}
        target='_blank'
        rel='noopener noreferrer'
      >
        Rastrear visita
      </a>
    )
  )
}
