import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { useFirebaseEvent } from 'hooks'
import { EVENT_STATUS, FIREBASE_EVENTS } from 'app-constants'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import './style.scss'

export function Error({ errorInfo }) {
  const { message, title } = errorInfo || {}
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION_ERROR, EVENT_STATUS.ERROR)
  }, [sendEvent])

  function handleClick() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='container-error'>
      <div className='container-info'>
        <div className='container-icon'>
          <STATICS.INFO />
        </div>
        <span className='title'>{title}</span>
        <span className='description'>{message}</span>
      </div>
      <div className='container-buttons'>
        <Button onClick={() => handleClick()} className='button accept' color='primary'>
          Ok! Entendi
        </Button>
      </div>
    </div>
  )
}
