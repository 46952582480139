import { useEffect, useState } from 'react'
import { getApp, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { FIREBASE_CONFIG, FIREBASE_DB_NAME } from 'app-constants'

export function useFirebase() {
  const [database, setDatabase] = useState(null)

  useEffect(() => {
    function handleFirebaseConnection() {
      try {
        const currentAppInstance = getApp(FIREBASE_DB_NAME)
        return currentAppInstance
      } catch {
        const newAppInstance = initializeApp(FIREBASE_CONFIG, FIREBASE_DB_NAME)
        return newAppInstance
      }
    }

    const app = handleFirebaseConnection()
    const databaseInstance = getFirestore(app, FIREBASE_DB_NAME)

    setDatabase(databaseInstance)
  }, [])

  return { database }
}
