import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from 'slices/modal'
import {
  diffInDays,
  formatCurrency,
  formatDate,
  isBefore,
  sendWhatsappMessage,
  subtractDays,
} from 'utils'
import { Tag, Button } from 'components'
import { STATICS } from 'statics'
import {
  BANKSLIPS,
  PIX_PAYMENT_ERROR_MESSAGE,
  CREDIT_CARD_ERROR_MESSAGE,
  COMPONENTS_RENDER,
  MAX_DAYS_OVERDUE_BILL,
  FIREBASE_EVENTS,
  BILLS_TAGS,
  TAGS_INFO,
} from 'app-constants'
import { useToaster, getPixPaymentInfo, generatePaymentLink, useFirebaseEvent } from 'hooks'
import { setDrawer } from 'slices/drawer'
import './style.scss'

export function FinancialLine({
  value,
  urlFile,
  dueDate,
  paymentDate,
  paid,
  btnLabel = 'Baixar fatura',
  contractId,
  codeBar,
  setRenderClerkAlert,
  renderClerkAlert,
  id,
  qrCode,
  tags = [],
}) {
  const [barCodeTooltipText, setBarCodeTooltipText] = useState('')
  const [isLoadingPix, setIsLoadingPix] = useState(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState(false)
  const {
    selected: { id: idContract, unity },
  } = useSelector(state => state.contract)
  const { name } = useSelector(state => state.customer.info)
  const { showErrorToast } = useToaster()
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  function handleClick() {
    setBarCodeTooltipText('Código copiado!')
    sendEvent(FIREBASE_EVENTS.CLICK_COPY_BILLET_CODE_LIST)
  }

  async function handlePixButtonClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_PIX_PAYMENT_LIST)

    try {
      setIsLoadingPix(true)

      if (qrCode) await handlePixPayment()
      else await handlePixRedirectLink()
    } finally {
      setIsLoadingPix(false)
    }
  }

  async function handlePixRedirectLink() {
    try {
      const {
        data: { paymentLink },
      } = await generatePaymentLink({ invoiceId: id })

      window.open(paymentLink)
    } catch (error) {
      console.error(error)
      showErrorToast(PIX_PAYMENT_ERROR_MESSAGE)
    }
  }

  async function handlePixPayment() {
    try {
      setIsLoadingPix(true)
      const params = {
        value,
        contractId,
        invoiceId: id,
        name,
      }
      const { data } = await getPixPaymentInfo(params)

      if (data?.qrcodetext)
        dispatch(setModal({ key: 'pix_payment', data: { qrCode: data?.qrcodetext } }))
      else showErrorToast(PIX_PAYMENT_ERROR_MESSAGE)
    } catch (error) {
      console.error(error)
      showErrorToast(PIX_PAYMENT_ERROR_MESSAGE)
    } finally {
      setIsLoadingPix(false)
    }
  }

  async function handleCreditCardPayment() {
    sendEvent(FIREBASE_EVENTS.CLICK_CREDIT_CARD_LIST)

    try {
      setIsLoadingCreditCard(true)
      const params = {
        invoiceId: id,
      }
      const {
        data: { paymentLink },
      } = await generatePaymentLink(params)
      window.open(paymentLink)
    } catch (error) {
      console.error(error)
      showErrorToast(CREDIT_CARD_ERROR_MESSAGE)
    } finally {
      setIsLoadingCreditCard(false)
    }
  }

  function handleMouseLeave() {
    setBarCodeTooltipText('')
  }

  function handleTalkToAttendant() {
    sendWhatsappMessage({ message: 'Olá :)', unity })
  }

  function handleOpenNegotiation() {
    dispatch(setDrawer('negotiation'))
  }

  function getTagInfo() {
    const isNegotiated = tags.includes(BILLS_TAGS.IN_NEGOTIATION)

    if (isNegotiated) return TAGS_INFO.NEGOTIATED

    return paid ? TAGS_INFO.PAID : TAGS_INFO.OPEN
  }

  function renderTag() {
    const info = getTagInfo()

    return (
      <div className='wrapper'>
        <Tag className='tag' text={info.TEXT} color={info.COLOR} />
      </div>
    )
  }

  function renderClerkButton() {
    if (!renderClerkAlert) setRenderClerkAlert(true)
    return (
      <Button className='clerk' color='primary' onClick={() => handleTalkToAttendant()}>
        Falar com Atendente
      </Button>
    )
  }

  function renderTooltip() {
    const isExpired = diffInDays(new Date(), dueDate) > BANKSLIPS.MAX_DAYS

    const tooltipText = isExpired
      ? 'É necessário gerar um novo boleto. Por favor, entre em contato conosco.'
      : 'Seu boleto estará disponível em breve.'

    return (
      <>
        <div className='tooltip' data-tip={tooltipText}>
          <STATICS.DENSE_QUESTION />
        </div>
        <ReactTooltip />
      </>
    )
  }

  function renderDownload() {
    const isBankSlip = !paid
    let component

    if (!!urlFile) {
      component = (
        <>
          {codeBar && (
            <CopyToClipboard text={codeBar}>
              <Button
                data-for='barcode-tip'
                data-tip=''
                onClick={handleClick}
                onMouseLeave={handleMouseLeave}
                className='button code-button'
                icon={<STATICS.BAR_CODE />}
                color='primary'
                outlined
              >
                Copiar código
              </Button>
            </CopyToClipboard>
          )}
          {codeBar && (
            <CopyToClipboard text={codeBar}>
              <div data-for='barcode-tip-mob' data-tip='Código copiado!' className='icon'>
                <STATICS.BAR_CODE />
              </div>
            </CopyToClipboard>
          )}
          <div className='icon download' onClick={() => renderDownloadClick(urlFile)}>
            <STATICS.DOWNLOAD />
          </div>
          <Button
            className='button'
            icon={<STATICS.ALTERNATIVE_DOWNLOAD />}
            color='primary'
            onClick={() => renderDownloadClick(urlFile)}
          >
            {btnLabel}
          </Button>
          <ReactTooltip id='barcode-tip-mob' />
          <ReactTooltip id='barcode-tip' getContent={() => barCodeTooltipText} />
        </>
      )
    } else if (!urlFile && isBankSlip) {
      component = COMPONENTS_RENDER.BANK_SLIPS_CLERK_BUTTON ? renderClerkButton() : renderTooltip()
    }

    return component
  }

  function renderDownloadClick(urlFile) {
    if (!paid) {
      sendEvent(FIREBASE_EVENTS.CLICK_DOWNLOAD_BILLETS_LIST)
    }
    window.open(urlFile)
  }

  function renderContractId() {
    if (!paid) {
      return <p className='bill-id'>{id}</p>
    }
    return null
  }

  function renderPixPaymentButton() {
    const showPixButton = !paid

    if (!showPixButton) return null

    return (
      <>
        <button type='button' className='icon pix-icon' onClick={() => handlePixPayment()}>
          <STATICS.PIX_ICON />
        </button>
        <Button
          isLoading={isLoadingPix}
          disabled={isLoadingPix}
          onClick={() => handlePixButtonClick()}
          className='button pix-button'
          icon={!isLoadingPix && <STATICS.PIX_ICON />}
          color='primary'
          outlined
        >
          Pagar com pix
        </Button>
      </>
    )
  }

  function renderCreditCardPaymentButton() {
    const showButton = !paid && COMPONENTS_RENDER.CREDIT_CARD_PAYMENT_BUTTON

    if (!showButton) return null

    return (
      <>
        <button type='button' className='icon card-icon' onClick={() => handleCreditCardPayment()}>
          <STATICS.CREDIT_CARD />
        </button>
        <Button
          isLoading={isLoadingCreditCard}
          disabled={isLoadingCreditCard}
          onClick={() => handleCreditCardPayment()}
          className='button card-button'
          icon={!isLoadingCreditCard && <STATICS.CREDIT_CARD />}
          color='primary'
          outlined
        >
          Pagar com Cartão
        </Button>
      </>
    )
  }

  function renderNegotiationButton() {
    return (
      <Button
        onClick={handleOpenNegotiation}
        className='button'
        icon={<STATICS.BAR_CODE />}
        color='primary'
        outlined
      >
        Negociar Fatura
      </Button>
    )
  }

  function renderActions() {
    const isBankSlip = !paid
    const priorDate = subtractDays(new Date(), MAX_DAYS_OVERDUE_BILL)
    const isBreachOfAggrement = tags.includes(BILLS_TAGS.BREACH_OF_AGREEMENT)
    const shouldShowNegotiationAction =
      isBefore(dueDate, priorDate) && isBankSlip && isBreachOfAggrement
    const isInNegotiation = tags.includes(BILLS_TAGS.IN_NEGOTIATION)

    if (isInNegotiation) return null

    if (shouldShowNegotiationAction) {
      return renderNegotiationButton()
    }

    return (
      <>
        {renderCreditCardPaymentButton()}
        {renderPixPaymentButton()}
        {renderDownload()}
      </>
    )
  }

  return (
    <div className='financial-line-component'>
      <div className='wrapper'>
        <p className='value'>{formatCurrency(value)}</p>
        <p className='date'>{formatDate(dueDate)}</p>
        {!!paymentDate && <p className='payment-date'>{formatDate(paymentDate)}</p>}
        {renderContractId()}
      </div>
      {renderTag()}
      <div className='actions'>{renderActions()}</div>
    </div>
  )
}
