import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FIREBASE_EVENTS, NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { Button, Card } from 'components'
import { setDrawer, setStage } from 'slices/drawer'
import { getMassiveMessage } from 'utils'
import { useFirebaseEvent } from 'hooks'
import './style.scss'

export function MassiveWarning() {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()
  const {
    virtualSupportMassive: { message },
  } = useSelector(({ massive }) => massive)

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_LOCATION_WITH_MASSIVE)
  }, [sendEvent])

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleContinue() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SIGNAL_VERIFY))
  }

  function renderMassiveWarning() {
    const { title, description } = getMassiveMessage(message)

    return (
      <>
        <p className='card-title'>{title}</p>
        <p className='text'>{description}</p>
      </>
    )
  }

  function renderButtons() {
    return (
      <div className='links'>
        <Button className='button' color='primary' size='large' onClick={handleClose}>
          Ok, entendi!
        </Button>
        <Button className='button' color='primary' size='large' outlined onClick={handleContinue}>
          Ainda preciso de suporte técnico
        </Button>
      </div>
    )
  }

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <h3 className='title'>Central de notificação</h3>
      </div>
      <Card className='massive-waring-card'>{renderMassiveWarning()}</Card>
      {renderButtons()}
    </div>
  )
}
