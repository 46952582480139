import React from 'react'
import { KeycloakProvider } from '@react-keycloak/web'
import { Provider } from 'react-redux'
import { useHistory, matchPath } from 'react-router-dom'
import {
  getCustomer,
  getRequiredPasswordUpdate,
  useFirebaseLogin,
  useRemindPasswordLater,
} from 'hooks'
import { Footer, Header, Drawer, Modal } from 'components'
import {
  JWT_TOKEN_KEY,
  AUTH_SUCCESS_EVENT,
  COMPONENTS_RENDER,
  DYNAMIC_RENDER_COMPONENTS,
  ROUTES,
  ORIGIN,
} from 'app-constants'
import { ApplicationRedirect } from 'pages'
import { Routes } from 'routes'
import { RESET_PASSWORD_PAGE_PATH, routes } from 'routes/routes'
import keycloak from './keycloack'
import { store } from './store'

function App() {
  const { isRemindPasswordLater } = useRemindPasswordLater()
  const history = useHistory()
  const currentRoute = routes.find(route =>
    matchPath(history.location.pathname, {
      ...route,
    })
  )
  const { sendLoginEvent } = useFirebaseLogin()

  function handleRedirect(pathname, search = null) {
    history.push({
      pathname,
      search,
    })
  }

  async function getIsFirstAccess() {
    try {
      const { data } = await getCustomer()
      sendLoginEvent(data.document)

      const { search } = history.location

      if (data.isFirstAccess) handleRedirect(RESET_PASSWORD_PAGE_PATH)
      else if (currentRoute?.privateRoute && !isRemindPasswordLater) {
        const {
          data: { shouldChangePassword },
        } = await getRequiredPasswordUpdate(data.customerId, ORIGIN)

        if (shouldChangePassword) handleRedirect(ROUTES.REQUIRED_PASSWORD_UPDATE.PATH)
        else handleRedirect(ROUTES.HOME.PATH, search)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function handleOnAuthSuccessEvent(event) {
    if (event === AUTH_SUCCESS_EVENT) {
      localStorage.setItem('SUMICITY_SESSION_TIME', Date.now())

      getIsFirstAccess()
    }
  }

  function renderContent() {
    const hasApplicationRedirect = COMPONENTS_RENDER[DYNAMIC_RENDER_COMPONENTS.APPLICATION_REDIRECT]

    if (hasApplicationRedirect) return <ApplicationRedirect />

    return (
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={{ checkLoginIframe: false }}
        onTokens={tokens => {
          if (tokens.token) {
            localStorage.setItem(JWT_TOKEN_KEY, tokens.token)
          }
        }}
        onEvent={event => handleOnAuthSuccessEvent(event)}
      >
        <Provider store={store}>
          <Header />
          <Routes />
          <Footer />
          <Drawer />
          <Modal />
        </Provider>
      </KeycloakProvider>
    )
  }

  return renderContent()
}

export default App
