import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import {
  COPY_CODE_MESSAGE,
  COPIED_CODE_MESSAGE,
  COUPON_ERROR_MESSAGE,
  PLAN_STATUS_KEY,
  FIREBASE_EVENTS,
} from 'app-constants'
import { Button, Card } from 'components'
import { getCouponCode, useFirebaseEvent, useToaster } from 'hooks'
import { STATICS } from 'statics'
import { setModal } from 'slices/modal'
import './style.scss'

export function ReferFriendsCard({
  title,
  description,
  onClick,
  disabled,
  className,
  dataCy,
  template,
}) {
  const [couponCode, setCouponCode] = useState(null)
  const [isLoading, seIsLoading] = useState(false)
  const [iconTooltipText, seIconTooltipText] = useState(COPY_CODE_MESSAGE)
  const {
    selected: { contractStatus },
  } = useSelector(({ contract }) => contract)
  const { showErrorToast } = useToaster()
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  function handleIconClick() {
    seIconTooltipText(COPIED_CODE_MESSAGE)
  }

  function handleIconMouseLeave() {
    seIconTooltipText(COPY_CODE_MESSAGE)
  }

  async function handleGenerateCouponClick() {
    seIsLoading(true)
    sendEvent(FIREBASE_EVENTS.CLICK_ON_REFERER_FRIEND)

    try {
      const { data } = await getCouponCode()
      setCouponCode(data.code)
      sendEvent(FIREBASE_EVENTS.REFERER_FRIEND_SUCCESS)
    } catch (error) {
      sendEvent(FIREBASE_EVENTS.REFERER_FRIEND_ERROR)
      showErrorToast(COUPON_ERROR_MESSAGE)
      console.error(error)
    } finally {
      seIsLoading(false)
    }
  }

  if (contractStatus === PLAN_STATUS_KEY.ST_CONT_CANCELADO) return null

  return (
    <Card
      className={`generic-card-component ${className ||
        ''} -${template} card-component-refer-friend`}
      onClick={onClick}
      dataCy={dataCy}
    >
      <div className='right-upper-menu'>
        <div className='icon -lightBlue' data-tip='Informações sobre o programa'>
          <button type='button' onClick={() => dispatch(setModal({ key: 'refer_friend' }))}>
            <STATICS.DENSE_QUESTION />
          </button>
        </div>
        <ReactTooltip />
      </div>
      <div className='wraper-content'>
        <div className='header'>
          <STATICS.REFER_QUESTION className='icon' />
        </div>
        <div className='content'>
          <h3 className='title'>{title}</h3>
          <p className='text'>{description}</p>
        </div>
        <div className='action'>
          {!couponCode ? (
            <Button
              className='button'
              disabled={disabled}
              onClick={handleGenerateCouponClick}
              isLoading={isLoading}
            >
              Gerar cupom
            </Button>
          ) : (
            <CopyToClipboard text={couponCode}>
              <div
                className='button-coupon'
                onClick={handleIconClick}
                onMouseLeave={handleIconMouseLeave}
                data-for='barcode-tip'
                data-tip={iconTooltipText}
              >
                <div className='text-coupon'>{couponCode}</div>
                <div className='icon barcode'>
                  <STATICS.COPY />
                  <ReactTooltip id='barcode-tip' getContent={() => iconTooltipText} />
                </div>
              </div>
            </CopyToClipboard>
          )}
        </div>
      </div>
    </Card>
  )
}
