import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { EVENT_STATUS, FIREBASE_EVENTS, SERVICE_ORDER_TYPES, STATUS_CODE } from 'app-constants'
import { setStage } from 'slices/drawer'
import { INFO } from 'statics/common'
import { Button, Card, ContextLoader, Input, SelectInput } from 'components'
import {
  getHouseViability,
  getServiceOrderDetails,
  useFirebaseEvent,
  useForm,
  validateZipCode,
} from 'hooks'
import { ADDRESS_TYPE_VALUES, VALIDATION_MESSAGES, ADDRESS_CHANGE_STAGES } from '../../constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  zipcode: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .min(9, VALIDATION_MESSAGES.FORMAT_ERROR),
  street: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  number: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
  complement: Yup.string(),
  neighborhood: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  city: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  state: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
})

export function AddressChangeForm({ data, setData }) {
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [editable, setEditable] = useState({
    street: false,
    neighborhood: false,
    complement: false,
    number: false,
    city: false,
    state: false,
  })
  const { selected: selectedContract } = useSelector(({ contract }) => contract)
  const bankSlips = useSelector(state => state.customer.financial.bankSlips)
  const { sendEvent } = useFirebaseEvent()
  const dispatch = useDispatch()
  const form = useForm({
    initialValues: {
      zipcode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      addressType: ADDRESS_TYPE_VALUES.HOUSE,
      preReservationUUID: null,
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    function getHasDueDateBankSlips() {
      const today = new Date()
      today.setHours(0, 0, 0)

      return (
        bankSlips.content &&
        bankSlips.content.some(bills => new Date(`${bills.dueDate} 00:00`) < today)
      )
    }

    const hasDueDateBankSlips = getHasDueDateBankSlips()

    if (hasDueDateBankSlips) dispatch(setStage(ADDRESS_CHANGE_STAGES.OPEN_INVOICES))
  }, [bankSlips, dispatch])

  async function submitHouse(values) {
    const { city, neighborhood, number, state, street } = values

    try {
      setLoading(true)
      const params = {
        city,
        number,
        state,
        street,
        district: neighborhood,
      }

      const {
        data: { preReservationUUID },
      } = await getHouseViability(params)
      sendEvent(FIREBASE_EVENTS.INFORM_NEW_ADDRESS)
      setData({ ...values, preReservationUUID })
      dispatch(setStage(ADDRESS_CHANGE_STAGES.BOOKING))
    } catch (ex) {
      sendEvent(FIREBASE_EVENTS.INFORM_NEW_ADDRESS, EVENT_STATUS.ERROR)
      dispatch(setStage(ADDRESS_CHANGE_STAGES.ADDRESS_INVIABILITY))
    } finally {
      setLoading(false)
    }
  }

  function submitCondominium() {
    dispatch(setStage(ADDRESS_CHANGE_STAGES.CONDOMINIUM))
  }

  async function handleSubmit(values) {
    const { addressType } = values
    setData(values)

    switch (addressType) {
      case ADDRESS_TYPE_VALUES.CONDOMINIUM:
        submitCondominium()
        break
      case ADDRESS_TYPE_VALUES.HOUSE:
      default:
        submitHouse(values)
        break
    }
  }

  useEffect(() => {
    const { zipcode, city, neighborhood, number, state, street } = form.values

    const requiredFields = {
      zipcode,
      street,
      number,
      neighborhood,
      city,
      state,
    }
    const hasErrors = Object.values(form.errors).some(error => error)
    const isEmpty = Object.values(requiredFields).some(x => x === null || x === '')

    setDisabled(hasErrors || isEmpty)
  }, [form.errors, form.values])

  useEffect(() => {
    async function loadServiceOrder() {
      try {
        const result = await getServiceOrderDetails(
          selectedContract.id,
          SERVICE_ORDER_TYPES.ADDRESS_CHANGE
        )
        const {
          expectedInstallationDate,
          expectedInstallationTurn,
          type,
          isDateAbleToReschedule,
          technician,
        } = result.data
        setData({
          ...data,
          type,
          schedulingDate: expectedInstallationDate,
          turn: expectedInstallationTurn,
          isDateAbleToReschedule,
          technician,
          serviceOrderInfo: result.data,
        })

        if (result.data) dispatch(setStage(ADDRESS_CHANGE_STAGES.SERVICE_ORDER_STATUS))
      } catch (error) {
        if (error?.response?.status !== STATUS_CODE.NOT_FOUND) {
          console.error(error)
          const stage =
            error?.response?.status === STATUS_CODE.FORBIDDEN
              ? ADDRESS_CHANGE_STAGES.UNAVAILABLE_FEATURE
              : ADDRESS_CHANGE_STAGES.ERROR
          dispatch(setStage(stage))
        }
      } finally {
        setLoading(false)
      }
    }

    loadServiceOrder()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract.id, dispatch])

  useEffect(() => {
    async function loadCity() {
      try {
        setLoading(true)
        const { data } = await validateZipCode(form.values.zipcode)

        form.setValues({
          ...form.values,
          neighborhood: data.district || '',
          city: data.city || '',
          state: data.state || '',
          uf: data.uf || '',
          street: data.street || '',
          ibgeCodeCity: data?.ibgeCodeCity || '',
        })

        setEditable({
          street: !data.street,
          neighborhood: !data.district,
          complement: true,
          number: true,
        })

        form.setErrors({ zipcode: false })
      } catch {
        form.setErrors({ zipcode: true })
      } finally {
        setLoading(false)
      }
    }

    if (form.values.zipcode.length === 10) {
      loadCity()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.zipcode])

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='address-update-component'>
      <h3 className='title'>Altere seu endereço de instalação</h3>
      <span className='subtitle'>Solicite aqui a mudança do seu endereço</span>
      <span className='info'>
        <INFO />
        Essa solicitação <strong className='bolder'>não</strong> gera custo adicional
      </span>
      <h4>Endereço Atual</h4>
      <Card className='card'>
        <span className='title'>
          Cep:
          <span className='text'>{' ' + selectedContract.address.zipCode}</span>
        </span>
        <span className='title'>
          Endereço:
          <span className='text'>{' ' + selectedContract.address.street}</span>
        </span>
        <span className='title'>
          Bairro:
          <span className='text'>{' ' + selectedContract.address.neighborhood}</span>
        </span>
        <span className='title'>
          Cidade:
          <span className='text'>{' ' + selectedContract.address.city}</span>
        </span>
      </Card>
      <form className='form' onSubmit={form.handleSubmit}>
        <div className='content'>
          <label className='label'>Cadastrar seu novo endereço</label>
          <Input
            className='input'
            name='zipcode'
            label='CEP*'
            required='true'
            onChange={form.handleChange}
            value={form.values.zipcode}
            hasError={!!form.errors?.zipcode}
            errorMessage='CEP inválido'
            mask='99.999-999'
          />
          <SelectInput
            className='input'
            name='addressType'
            label='Tipo de Endereço*'
            required='true'
            onChange={form.handleChange}
            value={form.values.addressType}
            disabled={!form.values.zipcode}
          >
            <option
              key={ADDRESS_TYPE_VALUES.HOUSE}
              className='option'
              value={ADDRESS_TYPE_VALUES.HOUSE}
            >
              Casa
            </option>
            <option
              key={ADDRESS_TYPE_VALUES.CONDOMINIUM}
              className='option'
              value={ADDRESS_TYPE_VALUES.CONDOMINIUM}
            >
              Condomínio
            </option>
          </SelectInput>
          <Input
            className='input'
            name='street'
            label='Endereço*'
            required='true'
            onChange={form.handleChange}
            value={form.values.street}
            hasError={form.errors.street}
            disabled={!editable.street}
          />
          <Input
            className='input'
            name='number'
            label='Número *'
            required='true'
            onChange={form.handleChange}
            value={form.values.number}
            hasError={form.errors.number}
            disabled={!editable.number}
          />
          <Input
            className='input'
            name='complement'
            label='Complemento (opcional)'
            onChange={form.handleChange}
            value={form.values.complement}
            hasError={form.errors.complement}
            disabled={!editable.complement}
          />
          <Input
            className='input'
            name='neighborhood'
            label='Bairro *'
            required='true'
            onChange={form.handleChange}
            value={form.values.neighborhood}
            hasError={form.errors.neighborhood}
            disabled={!editable.neighborhood}
          />
          <Input
            className='input'
            name='city'
            label='Cidade *'
            required='true'
            onChange={form.handleChange}
            value={form.values.city}
            hasError={form.errors.city}
            disabled={!editable.city}
          />
          <Input
            className='input'
            name='city'
            label='Estado *'
            required='true'
            onChange={form.handleChange}
            value={form.values.state}
            hasError={form.errors.state}
            disabled={!editable.state}
          />
        </div>
        <Button
          type='submit'
          color='primary'
          size='large'
          isLoading={form.isSubmitting}
          disabled={disabled}
        >
          Continuar
        </Button>
      </form>
    </div>
  )
}
