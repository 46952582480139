import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { STATICS } from 'statics'
import { Button } from 'components/button'
import { setDrawer } from 'slices/drawer'
import { FIREBASE_EVENTS } from 'app-constants'
import { useFirebaseEvent } from 'hooks'
import './style.scss'

export function ModemInProgress() {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.WIFI_UPDATE_IN_PROGRESS)
  }, [sendEvent])

  function handleClick() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='modem-in-progress-component'>
      <div className='icon'>
        <STATICS.INFO_CIRCLE />
      </div>
      <h3 className='title'>
        Você já possui uma solicitação para alteração da sua senha em andamento
      </h3>
      <p className='text'>Assim que a senha for alterada, você receberá uma confirmação.</p>
      <p className='text'>
        Por favor, aguarde essa confirmação antes de atualizar os dados nos dispositivos conectados
        à sua rede.
      </p>
      <p className='text'>
        Em caso de dúvidas, entre em contato com a nossa central de atendimento.
      </p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi!
      </Button>
    </div>
  )
}
